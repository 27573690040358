import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedTextField = styled(TextField)<TextFieldProps>(() => ({
  "& .MuiOutlinedInput-root": {
    paddingRight: 6,
    color: "#454545",
    borderRadius: 8,
    borderColor: "#454545",
    "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#5D26C5",
        borderWidth: 2,
      },

    "&.Mui-disabled": {
      color: "#191C1D",
      opacity: 0.38,
      borderColor: "rgba(25, 28, 29, 0.12)",
    },
  },
  "& .MuiFormLabel-root": {
    "&.Mui-focused": {
      color: "#5D26C5",
      fontWeight: 400,
    },
  },
}));

interface Props {
  label?: string;
  value?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  onClickClear?: React.MouseEventHandler<HTMLButtonElement> | (() => void);
  clear?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  testId?: string;
  name?: string;
  endAdornament?: React.ReactNode;
  fullWidth?: boolean;
  type?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  autoComplete?: "off" | "on" | string;
  className?: string;
  style?: object;
  inputProps?: object;
}

export function CustomTextField(props: Props) {
  const {
    label,
    error,
    helperText,
    disabled,
    placeholder,
    onChange,
    id,
    value,
    name,
    fullWidth,
    autoComplete,
    onBlur,
    onFocus,
    type,
    className,
    style,
    inputProps,
  } = props;

  return (
    <CustomizedTextField
      error={error}
      helperText={helperText}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
      onBlur={onBlur}
      onFocus={onFocus}
      fullWidth={fullWidth}
      autoComplete={autoComplete}
      className={className}
      id={id}
      type={type}
      style={style}
      inputProps={{ ...inputProps }}
    />
  );
}
