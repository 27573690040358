import { useState } from "react";
import { CustomButton } from "../../elements/CustomButton";
import { CustomButtonOutline } from "../../elements/CustomButtonOutline";
import { Divider, ListItemButton } from "@mui/material";
import { Bank } from "../../../hooks/useBanks";
import bancolombiaIcon from "../../../assets/banks/bancolombia.svg";
import nequiIcon from "../../../assets/banks/nequi.svg";
import daviplataIcon from "../../../assets/banks/daviplata.svg";
import daviviendaIcon from "../../../assets/banks/davivienda.svg";
import { StatusMessage } from "../../elements/StatusMessage";

const mainBankIcons = new Map([
  ["bancolombia", bancolombiaIcon],
  ["bancolombia_ach", bancolombiaIcon],
  ["bancolombia_pse", bancolombiaIcon],
  ["nequi", nequiIcon],
  ["nequi_ach", nequiIcon],
  ["nequi_pse", nequiIcon],
  ["daviplata", daviplataIcon],
  ["daviplata_ach", daviplataIcon],
  ["daviplata_pse", daviplataIcon],
  ["davivienda", daviviendaIcon],
  ["davivienda_ach", daviviendaIcon],
]);

const instantRails = new Set([
  "bancolombia_token",
  "nequi_token",
  "daviplata_api",
]);

interface BankSelectionProps {
  banks: Bank[];
  onBankSelection: (bankId: string) => void;
  bankSelectionLoading: boolean;
  pseDisabled?: boolean;
  achDisabled?: boolean;
  bancolombiaTokenDisabled?: boolean;
  isBusinessEntity?: boolean;
}

export const BankSelection = ({
  banks,
  onBankSelection,
  bankSelectionLoading,
  pseDisabled,
  achDisabled,
  bancolombiaTokenDisabled,
  isBusinessEntity,
}: BankSelectionProps) => {
  const [selectedBank, setSelectedBank] = useState("");
  const [showOtherBanks, setShowOtherBanks] = useState(false);

  let filteredBanks = banks
    .filter((bank) => (pseDisabled ? bank.payment_rail !== "pse" : true))
    .filter((bank) => (achDisabled ? bank.payment_rail !== "ach" : true))
    .filter((bank) =>
      bancolombiaTokenDisabled
        ? bank.payment_rail !== "bancolombia_token"
        : true
    );

  if (isBusinessEntity) {
    filteredBanks = filteredBanks.filter(
      (bank) => !instantRails.has(bank.payment_rail)
    );
  } else {
    if (
      filteredBanks.findIndex(
        (bank) => bank.payment_rail === "bancolombia_token"
      ) !== -1
    )
      filteredBanks = filteredBanks.filter(
        (bank) => bank.id !== "bancolombia_pse" && bank.id !== "bancolombia_ach"
      );
    if (
      filteredBanks.findIndex((bank) => bank.payment_rail === "nequi_token") !==
      -1
    )
      filteredBanks = filteredBanks.filter(
        (bank) => bank.id !== "nequi_pse" && bank.id !== "nequi_ach"
      );
    if (
      filteredBanks.findIndex(
        (bank) => bank.payment_rail === "daviplata_api"
      ) !== -1
    )
      filteredBanks = filteredBanks.filter(
        (bank) => bank.id !== "daviplata_pse" && bank.id !== "daviplata_ach"
      );
  }

  const mainBanks = Array.from(mainBankIcons.keys())
    .map((b) => filteredBanks.find((bank) => bank.id === b))
    .filter((b): b is Bank => b !== undefined);
  const otherBanks = filteredBanks
    .filter((bank) => !Array.from(mainBankIcons.keys()).includes(bank.id))
    .sort((a, b) => a.name.localeCompare(b.name));

  if (mainBanks.length === 0)
    return (
      <StatusMessage
        title="Lo sentimos."
        subtitle="Ups, no te puedes vincular con ningun banco en este momento... intenta más tarde o conctáctanos por WhatsApp al +57 3052926621."
        success={false}
      />
    );

  return (
    <div className="my-4">
      <div className="max-h-[355px] overflow-y-auto my-4 mx-2 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100">
        {showOtherBanks ? (
          otherBanks.map((bank, index) => (
            <div key={bank.id}>
              {index !== 0 && (
                <Divider
                  sx={{ marginLeft: "8px", marginRight: "16px" }}
                  light
                />
              )}
              <BankButtonText
                name={bank.name}
                isSelected={selectedBank === bank.id}
                onSelect={() => setSelectedBank(bank.id)}
              />
            </div>
          ))
        ) : (
          <div className="grid grid-cols-2 text-[#454545]">
            {mainBanks
              .filter((bank) => !pseDisabled || bank.payment_rail !== "pse")
              .map((bank) => (
                <BankButtonIcon
                  key={bank.id}
                  name={bank.name}
                  logo={mainBankIcons.get(bank.id)!}
                  isSelected={selectedBank === bank.id}
                  onSelect={() => setSelectedBank(bank.id)}
                  // disabled={recurrence && bank.payment_rail === "pse"}
                />
              ))}
          </div>
        )}
      </div>

      <div className="mb-[24px] space-y-3">
        {otherBanks.length > 0 && (
          <CustomButtonOutline
            label={showOtherBanks ? "Volver" : "Más bancos"}
            onClick={() => {
              setShowOtherBanks(!showOtherBanks);
              setSelectedBank("");
            }}
            fullWidth={true}
            disabled={otherBanks.length === 0}
          />
        )}
        <CustomButton
          label="Continuar"
          onClick={() => onBankSelection(selectedBank)}
          disabled={!selectedBank}
          fullWidth={true}
          loading={bankSelectionLoading}
        />
      </div>

      <div className="text-[14px] lg:text-[16px] font-[400] text-[#666666] mb-4 mt-4 text-center">
        Selecciona el banco con el cual quieres vincular tu cuenta para hacer
        pagos por Palomma.
      </div>
    </div>
  );
};

interface BankButtonTextProps {
  name: string;
  isSelected: boolean;
  onSelect: () => void;
  disabled?: boolean;
}

const BankButtonText = ({
  name,
  isSelected,
  onSelect,
  disabled,
}: BankButtonTextProps) => (
  <ListItemButton
    disabled={disabled}
    onClick={onSelect}
    sx={{
      marginLeft: "8px",
      marginRight: "16px",
      borderRadius: 0,
      padding: "12px 16px",
      justifyContent: "flex-start",
      textTransform: "uppercase",
      fontSize: "16px",
      fontWeight: 500,
      "&:hover": {
        backgroundColor: "#F4F4F4",
      },
    }}
  >
    <div
      className={
        isSelected
          ? "text-[#5D26C5] font-bold text-[16px] lg:text-[20px]"
          : "text-[16px] lg:text-[20px]"
      }
    >
      {name}
    </div>
  </ListItemButton>
);

interface BankButtonIconProps {
  name: string;
  logo: any;
  isSelected: boolean;
  onSelect: () => void;
  disabled?: boolean;
}

const BankButtonIcon = ({
  name,
  logo,
  isSelected,
  onSelect,
  disabled,
}: BankButtonIconProps) => (
  <div className="relative centerIcon py-2 px-4">
    <div className="grid justify-items-center">
      {disabled && (
        <div className="overlay flex items-center justify-center absolute top-0 bottom-4 left-0 right-0 text-center">
          <div className="text-xs font-bold text-white bg-red-600 bg-opacity-100 p-1 rounded-md">
            Suscripción <br /> incompatible
          </div>
        </div>
      )}
      <ListItemButton
        disabled={disabled}
        onClick={onSelect}
        sx={{ borderRadius: "50%", position: "relative", padding: 0 }}
      >
        <img
          className={
            isSelected
              ? "rounded-full border-2 border-[#5D26C5] border-opacity-100 opacity-100"
              : "rounded-full border-2 border-opacity-100 opacity-40"
          }
          src={logo}
          alt="..."
        />
      </ListItemButton>
      <div
        onClick={() => !disabled && onSelect()}
        className={
          (disabled ? "opacity-20 " : "cursor-pointer ") +
          (isSelected
            ? "text-[#5D26C5] font-bold text-[16px] lg:text-[20px]"
            : "text-[16px] lg:text-[20px]")
        }
      >
        {name}
      </div>
    </div>
  </div>
);
