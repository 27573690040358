import { useEffect } from "react";
import useSWR from "swr";
import { Auth, UserInformation } from "../layouts/PaymentRequestDetailsLayout";

export type Transaction = {
  id: string;
  amount_cents: number;
  merchant: string;
  status:
    | "PENDING"
    | "CANCELLED"
    | "PROCESSING"
    | "APPROVED"
    | "DECLINED"
    | "ERROR";
  user_information?: UserInformation;
  status_message?: string;
  credits?: number;
  redirect_url?: string;
  enforced_document_number?: string;
  merchant_display_name?: string;
  payment_url?: string;
  payment_rail_transaction_id?: string;
};

const useTransaction = (auth: Auth, transactionId: string | null) => {
  const { data, error, isLoading, mutate } = useSWR<Transaction>(
    () =>
      transactionId && [
        `${process.env.REACT_APP_API_URL}/transactions/${transactionId}`,
        auth,
      ],
    {
      refreshInterval: (transaction) =>
        transaction?.status === "PENDING" ||
        (transaction?.status === "PROCESSING" && transaction?.payment_url)
          ? 20000
          : transaction?.status === "PROCESSING"
          ? 1000
          : 0,
    }
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return {
    transaction: data,
    transactionLoading: isLoading,
    mutateTransaction: mutate,
  };
};

export default useTransaction;
