import { PaymentAccount } from "./usePaymentAccounts";
import useSWR from "swr";
import { Auth } from "../layouts/PaymentRequestDetailsLayout";
import { useEffect } from "react";

export type PaymentAccountWithRail = PaymentAccount &
  (
    | { payment_rail: "bancolombia_token" }
    | { payment_rail: "nequi_token" }
    | { payment_rail: "daviplata_api" }
    | { payment_rail: "pse"; acceptance_token: string }
    | { payment_rail: "ach" }
  );

const usePaymentAccount = (auth: Auth, accountId: string | null) => {
  const { data, error, isLoading, mutate } = useSWR<PaymentAccountWithRail>(
    () =>
      auth.token &&
      auth.user?.status === "REGISTERED" &&
      accountId && [
        `${process.env.REACT_APP_API_URL}/payment-accounts/${accountId}`,
        auth,
      ],
    {
      refreshInterval: (paymentAccount) =>
        paymentAccount?.status === "PENDING" &&
        (paymentAccount.payment_rail === "bancolombia_token" ||
          paymentAccount?.payment_rail === "nequi_token")
          ? 1000
          : 0,
    }
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return {
    paymentAccount: data,
    paymentAccountLoading: isLoading,
    mutatePaymentAccount: mutate,
  };
};

export default usePaymentAccount;
