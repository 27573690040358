import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

export const SingleLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="h-screen w-full overflow-auto flex justify-center items-center">
      <div className="max-w-[22rem] md:max-w-[27rem]">
        {children ? children : <Outlet />}
      </div>
    </div>
  );
};
