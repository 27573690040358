import { useEffect } from "react";
import useSWR from "swr";
import { Auth } from "../layouts/PaymentRequestDetailsLayout";

export type PaymentAccount = {
  id: string;
  bank: string;
  description: string;
  status: "PENDING" | "AVAILABLE" | "ERROR" | "PENDING_VERIFICATION";
  auth_url?: string;
  affiliate?: { document_number?: string };
  payment_rail:
    | "bancolombia_token"
    | "nequi_token"
    | "daviplata_api"
    | "pse"
    | "ach";
};

const usePaymentAccounts = (
  auth: Auth,
  filter?: (paymentAccount: PaymentAccount) => boolean
) => {
  const { data, error, isLoading, mutate } = useSWR<PaymentAccount[]>(
    () =>
      auth.user &&
      auth.user?.status === "REGISTERED" && [
        `${process.env.REACT_APP_API_URL}/payment-accounts`,
        auth,
      ]
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const filteredAccounts = filter ? data?.filter(filter) : data;

  return {
    paymentAccounts: filteredAccounts || null,
    paymentAccountsLoading: isLoading,
    mutatePaymentAccounts: mutate,
  };
};

export default usePaymentAccounts;
