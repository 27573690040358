import { StatusMessage } from "../components/elements/StatusMessage";
import { SingleLayout } from "../layouts/SingleLayout";

export default function Error() {
  return (
    <SingleLayout>
      <StatusMessage
        title="¡Lo sentimos!"
        subtitle="Hubo un error inesperado. Lo hemos reportado a nuestro equipo." // Ingresa tu teléfono si quieres que te contactemos.
        success={false}
        button={{
          text: "Volver",
          onClick: () => window.location.reload(),
        }}
      >
        {/* <CustomTextField
          label="Teléfono"
          inputProps={{ inputMode: "numeric" }}
          fullWidth={true}
        /> */}
      </StatusMessage>
    </SingleLayout>
  );
}
