import { IoIosArrowForward } from "react-icons/io";

interface props {
  title: String;
  onClick?: () => void;
}

export const OptionsBox = ({ title, onClick }: props) => {
  return (
    <div
      className="text-2xl flex items-center cursor-pointer hover:bg-gray-200 rounded p-4"
      onClick={onClick}
    >
      <div className="text-[20px] font-[400] pr-1 ">{title}</div>
      <IoIosArrowForward />
    </div>
  );
};
