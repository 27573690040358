import { useState } from "react";
import { CustomButton } from "./CustomButton";
import confetti from "canvas-confetti";
import { Coins } from "../../hooks/useCoins";
import { CustomButtonOutline } from "./CustomButtonOutline";
import logoGold from "../../assets/rewards/logo-gold.png";
import logoSilver from "../../assets/rewards/logo-silver.png";
import logoBronze from "../../assets/rewards/logo-bronze.png";
import logoPurple from "../../assets/rewards/logo-purple.png";

interface RewardsCarouselProps {
  authToken: string;
  handleDoneClick: () => void;
  coins: number;
  mutateCoins: (data?: Coins, shouldRevalidate?: boolean) => void;
}

export const RewardsCarousel = (props: RewardsCarouselProps) => {
  const [reward, setReward] = useState("");
  const [creditsAwarded, setCreditsAwarded] = useState(0);
  const [spin, setSpin] = useState(false);
  const [spinEnabled, setSpinEnabled] = useState(true);
  const [points, setPoints] = useState(props.coins);

  const spinTimes: { [id: string]: number } = {
    $50k: 4600,
    $10k: 4800,
    $5k: 5000,
    "25": 5200,
    "10": 5400,
  };

  const imageSrc: { [id: string]: string } = {
    $50k: logoGold,
    $10k: logoSilver,
    $5k: logoBronze,
    "25": logoPurple,
    "10": logoPurple,
  };

  const outcomeDescriptions: { [id: string]: string } = {
    $50k: "$50.000",
    $10k: "$10.000",
    $5k: "$5.000",
    "25": "25 puntos",
    "10": "10 puntos",
  };

  const handleSpinClick = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/rewards/spin`, {
      headers: {
        Authorization: `Bearer ${props.authToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    const data = await res.json();
    if (res.ok) {
      setSpin(true);
      setSpinEnabled(false);
      setPoints(props.coins - 100);
      setCreditsAwarded(data.credits_awarded);
      setTimeout(() => {
        setSpin(false);
        setTimeout(() => {
          setReward(data.outcome_id);
          confetti({
            particleCount: 100,
            startVelocity: 30,
            spread: 360,
            origin: {
              x: 0.75,
              y: 0.75,
            },
          });
          setPoints(props.coins - 100 + data.coins_awarded);
          setSpinEnabled(true);
        }, 1000);
      }, spinTimes[data.outcome_id]);
    } else {
      console.error(data);
    }
  };
  return (
    <div>
      <div className="text-[16px] font-[400] text-[#666666] mb-4">
        Gana descuentos para usar en tu proxima compra, o puntos Palomma para
        volver a girar.
      </div>
      <div className="flex justify-between items-center">
        <div className="flex items-center text-2xl font-[400] text-[#000000] mb-2">
          <div className="mr-2">⚫</div>
          <div>{points}</div>
        </div>
        <div className="text-palommaPurple font-bold">1 giro = 100 puntos</div>
      </div>
      {reward ? (
        <div className="flex my-4 bg-[#f4f3f2] rounded-lg p-4">
          <div>
            <img
              className="max-h-14 lg:max-h-16"
              src={imageSrc[reward]}
              alt="logo"
            />
          </div>
          <div className="mx-2">
            <div className="font-semibold">
              ¡Ganaste {outcomeDescriptions[reward]}!
            </div>
            {creditsAwarded ? (
              <div>
                Se aplicarán automaticamente en tu próxima compra por Palomma.
              </div>
            ) : (
              <div>Se han sumado a tu cuenta.</div>
            )}
          </div>
        </div>
      ) : points >= 100 || !spinEnabled ? (
        <div className="relative overflow-hidden w-full bg-gray-100 rounded-lg">
          <div className="absolute inset-y-0 w-1/5 border-4 border-palommaPurple rounded-lg mx-auto left-0 right-0"></div>
          <div className="flex w-[200%]">
            <div
              className={`flex w-1/2 justify-around animate-slide ${
                !spin ? "paused" : ""
              }`}
            >
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoGold}
                  alt="logo"
                />
                <div>$50.000</div>
              </div>
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoSilver}
                  alt="logo"
                />
                <div>$10.000</div>
              </div>
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoBronze}
                  alt="logo"
                />
                <div>$5.000</div>
              </div>
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoPurple}
                  alt="logo"
                />
                <div>25</div>
              </div>
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoPurple}
                  alt="logo"
                />
                <div>10</div>
              </div>
            </div>
            <div
              className={`flex w-1/2 justify-around animate-slide ${
                !spin ? "paused" : ""
              }`}
            >
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoGold}
                  alt="logo"
                />
                <div>$50.000</div>
              </div>
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoSilver}
                  alt="logo"
                />
                <div>$10.000</div>
              </div>
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoBronze}
                  alt="logo"
                />
                <div>$5.000</div>
              </div>
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoPurple}
                  alt="logo"
                />
                <div>25</div>
              </div>
              <div className="text-center">
                <img
                  className="max-h-14 lg:max-h-16"
                  src={logoPurple}
                  alt="logo"
                />
                <div>10</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex my-4 bg-[#f4f3f2] rounded-lg p-4">
          <div className="mx-2">
            <div className="font-semibold">¡Ganaste puntos por tu compra!</div>
            <div>Acumula 100 puntos y podrás ganar premios.</div>
          </div>
        </div>
      )}
      <div className="my-6 flex">
        {points >= 100 || !spinEnabled ? (
          reward && spinEnabled ? (
            <div className="flex w-full space-x-2">
              <CustomButton
                label={"Girar de nuevo"}
                fullWidth={true}
                onClick={() => {
                  setReward("");
                  props.mutateCoins(undefined);
                }}
              />
              <CustomButtonOutline
                label={"Finalizar"}
                fullWidth={true}
                onClick={props.handleDoneClick}
              />
            </div>
          ) : (
            <CustomButton
              label={"Gira y gana"}
              fullWidth={true}
              disabled={!spinEnabled}
              onClick={handleSpinClick}
            />
          )
        ) : (
          <div className="flex-grow ml-2">
            <CustomButton
              label={"Finalizar"}
              fullWidth={true}
              onClick={props.handleDoneClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};
