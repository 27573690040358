import { Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import {
  Auth,
  PaymentRequestDetailsLayout,
} from "./layouts/PaymentRequestDetailsLayout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Registration from "./pages/Registration";
import LinkAccount from "./pages/LinkAccount";
import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";
import { SWRConfig } from "swr";
import Error from "./pages/Error";

H.init("ldwwjldo", {
  tracingOrigins: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert urls you don't want to record here
    ],
  },
});

const fetcher = async ([url, auth]: [string, Auth | undefined]) => {
  const res = await fetch(url, {
    headers: auth?.token
      ? {
          Authorization: `Bearer ${auth.token}`,
        }
      : undefined,
  });

  if (res.status === 401) auth?.setToken(null);
  if (!res.ok) throw res;

  return res.json();
};

function App() {
  return (
    <ErrorBoundary customDialog={<Error />}>
      <SnackbarProvider
        SnackbarProps={{
          autoHideDuration: 10000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        }}
      >
        <SWRConfig
          value={{
            fetcher,
            shouldRetryOnError: false,
            revalidateOnFocus: false,
          }}
        >
          <Routes>
            <Route element={<PaymentRequestDetailsLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registro" element={<Registration />} />
              <Route path="/vincular-cuenta" element={<LinkAccount />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SWRConfig>
      </SnackbarProvider>
    </ErrorBoundary>
  );
}

export default App;
