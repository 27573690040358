import { Step, StepLabel, Stepper, StepperProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedStepper = styled(Stepper)<StepperProps>(() => ({
  "& .MuiStepLabel-root .Mui-completed": {
    color: "#5D26C5", // circle color (COMPLETED)
  },
  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
    color: "#5D26C5", // Just text label (COMPLETED)
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: "#5D26C5", // circle color (ACTIVE)
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: "#5D26C5", // Just text label (ACTIVE)
  },
  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
    fill: "#5D26C5", // circle's number (ACTIVE)
  },
  "& .MuiStepLabel-root .MuiStepIcon-text": {
    opacity: 0,
    fill: "rgba(0, 0, 0, 0.38)", // circle's number (ACTIVE)
  },

  "&.MuiStepper-root": {
    "&.css-z7uhs0-MuiStepConnector-line": {
      borderWidth: 8,
      borderColor: "none",
    },
  },
}));

interface props {
  activeStep?: number;
  steps: string[];
}

export const CustomSteppers = ({ steps, activeStep }: props) => {
  return (
    <div className="flex flex-col justify-center items-center my-6">
      <div className="py-4 w-full">
        <CustomizedStepper alternativeLabel activeStep={activeStep}>
          {steps.map((item, index) => (
            <Step key={index}>
              <StepLabel>{item}</StepLabel>
            </Step>
          ))}
        </CustomizedStepper>
      </div>
      <div className="w-[100%] border-b-4" />
    </div>
  );
};
