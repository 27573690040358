import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";

export type Bank = {
  id: string;
  name: string;
  linking_disabled: boolean;
  // logo_url: string;
  payment_rail:
    | "bancolombia_token"
    | "nequi_token"
    | "daviplata_api"
    | "pse"
    | "ach";
  rails_disabled_for_payments: Array<
    "bancolombia_token" | "nequi_token" | "daviplata_api" | "pse" | "ach"
  >;
};

const useBanks = () => {
  const { data, error, isLoading } = useSWRImmutable<Bank[]>([
    `${process.env.REACT_APP_API_URL}/banks`,
  ]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return { banks: data, banksLoading: isLoading };
};

export default useBanks;
