import { PropsWithChildren } from "react";
import { CustomButton } from "./CustomButton";
import { Divider } from "./Divider";
import successIcon from "./../../assets/status/success.png";
import failureIcon from "../../assets/status/failure.png";

interface StatusMessageProps {
  success?: boolean;
  title: string;
  subtitle: string;
  button?: {
    text: string;
    onClick: () => void;
  };
  hideIcon?: boolean;
}

export const StatusMessage = (props: PropsWithChildren<StatusMessageProps>) => {
  return (
    <div>
      {props.success !== undefined && !props.hideIcon && (
        <div className="w-[209px] h-[222px]">
          <img
            src={props.success ? successIcon : failureIcon}
            alt=""
            className="w-full h-full"
          />
        </div>
      )}

      <div className=" w-full my-4">
        <div className=" text-[25px] lg:text-[46px] leading-8 lg:leading-[3rem] font-[400] text-[#000000]">
          {props.title}
        </div>
      </div>

      <div className="text-[20px] mt-4  lg:text-[24px] font-[400] text-[#666666]">
        {props.subtitle}
      </div>

      {(props.button || props.children) && (
        <div>
          <div className="my-4">
            <Divider />
          </div>

          {props.children}

          {props.button && (
            <div className="pb-4">
              <CustomButton
                label={props.button.text}
                disabled={false}
                fullWidth={true}
                onClick={props.button.onClick}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
