import { BlockTitles } from "../../elements/BlockTitles";
import { CustomButton } from "../../elements/CustomButton";
import { CustomInputSelect } from "../../elements/CustomInputSelect";
import { CustomTextField } from "../../elements/CustomTextField";
import { CustomCheckbox } from "../../elements/CustomCheckbox";
import { useState } from "react";

export type PersonUserInfo = {
  user_type: "PERSON";
  first_name: string;
  last_name: string;
  email: string;
  document_type: string;
  document_number: string;
};

export type BusinessUserInfo = {
  user_type: "BUSINESS";
  business_name: string;
  email: string;
  document_type: string;
  document_number: string;
};

type RegistrationProps = {
  onContinue: (userInfo: PersonUserInfo | BusinessUserInfo) => void;
  loading: boolean;
  defaultFirstName?: string;
  defaultLastName?: string;
  defaultEmail?: string;
  defaultDocumentType?: string;
  defaultDocumentNumber?: string;
  defaultBusinessName?: string;
  defaultEntityType?: "legal" | "natural";
};

export const RegisterUser = (props: RegistrationProps) => {
  const [firstName, setFirstName] = useState(props.defaultFirstName ?? "");
  const [lastName, setLastName] = useState(props.defaultLastName ?? "");
  const [email, setEmail] = useState(props.defaultEmail ?? "");
  const [documentType, setDocumentType] = useState(
    props.defaultDocumentType ?? "CC"
  );
  const [documentNumber, setDocumentNumber] = useState(
    props.defaultDocumentNumber ?? ""
  );
  const [dataTerms, setDataTerms] = useState(false);
  const [terms, setTerms] = useState(false);
  const [entityType, setEntityType] = useState<"natural" | "legal">(
    props.defaultEntityType ?? "natural"
  );
  const [businessName, setBusinessName] = useState(
    props.defaultBusinessName ?? ""
  );

  return (
    <div className="my-6">
      <BlockTitles
        title="Bienvenido"
        subtitle="Cuéntanos de ti. Esto nos ayudará a hacer tus pagos de manera rápida y sencilla. Solo te pediremos estos datos una vez."
      />

      <div className="my-4 w-full">
        <div className="my-4">
          <CustomInputSelect
            label="Tipo de persona"
            fullWidth={true}
            documentTypes={[
              { value: "natural", label: "Natural" },
              { value: "legal", label: "Jurídica" },
            ]}
            value={entityType}
            onChange={(e) => {
              setFirstName("");
              setLastName("");
              setEmail("");
              setDocumentType(e.target.value === "natural" ? "CC" : "NIT");
              setDocumentNumber("");
              setBusinessName("");
              setEntityType(e.target.value === "natural" ? "natural" : "legal");
            }}
          />
        </div>
        {entityType === "natural" && (
          <>
            <div className="flex flex-row">
              <CustomTextField
                label="Nombre"
                fullWidth={false}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <div className="mx-2" />
              <CustomTextField
                label="Apellidos"
                fullWidth={false}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <CustomTextField
                label="Correo"
                fullWidth={true}
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </div>
            <div className="mt-4 flex flex-row">
              <div className="w-[100px] mr-4">
                <CustomInputSelect
                  label="Doc."
                  fullWidth={true}
                  documentTypes={[
                    { value: "CC" },
                    { value: "CE" },
                    { value: "TI" },
                  ]}
                  value={documentType}
                  onChange={(e) => setDocumentType(e.target.value)}
                />
              </div>

              <div className="w-full">
                <CustomTextField
                  label="Número de identificación"
                  fullWidth={true}
                  value={documentNumber}
                  onChange={(e) => setDocumentNumber(e.target.value)}
                />
              </div>
            </div>
          </>
        )}
        {entityType === "legal" && (
          <>
            <div className="flex flex-row">
              <CustomTextField
                label="Razón social"
                fullWidth={true}
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <CustomTextField
                label="Correo"
                fullWidth={true}
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </div>
            <div className="mt-4 flex flex-row">
              <div className="w-full">
                <CustomTextField
                  label="NIT"
                  fullWidth={true}
                  value={documentNumber}
                  onChange={(e) => setDocumentNumber(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

        <div className="mt-6">
          <CustomCheckbox
            label={
              <>
                {"Autorizo el tratamiento de mis datos personales según la "}
                <a
                  href="https://www.palomma.com/politica-de-privacidad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>política de tratamiento de datos personales</u>
                </a>
              </>
            }
            value={dataTerms}
            onChange={() => setDataTerms(!dataTerms)}
          />
          <CustomCheckbox
            label={
              <>
                {"Acepto los "}
                <a
                  href="https://www.palomma.com/terminos-y-condiciones"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>términos y condiciones</u>
                </a>
              </>
            }
            value={terms}
            onChange={() => setTerms(!terms)}
          />
          <CustomButton
            label="Ingresar"
            onClick={() =>
              props.onContinue(
                entityType === "natural"
                  ? {
                      user_type: "PERSON",
                      first_name: firstName,
                      last_name: lastName,
                      email,
                      document_type: documentType,
                      document_number: documentNumber,
                    }
                  : {
                      user_type: "BUSINESS",
                      business_name: businessName,
                      email,
                      document_type: documentType,
                      document_number: documentNumber,
                    }
              )
            }
            fullWidth={true}
            disabled={
              ((!firstName || !lastName) && entityType === "natural") ||
              (!businessName && entityType === "legal") ||
              !/\S+@\S+\.\S+/.test(email) ||
              !documentType ||
              documentNumber.length < 6 ||
              !dataTerms ||
              !terms
            }
            loading={props.loading}
          />
        </div>
      </div>
    </div>
  );
};
