import { useState } from "react";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  RegisterUser as RegistrationTemplate,
  PersonUserInfo,
  BusinessUserInfo,
} from "../components/templates/registration/RegisterUser";
import { PaymentRequestOutletContext } from "../layouts/PaymentRequestDetailsLayout";
import { CustomSteppers } from "../components/elements/CustomSteppers";

////////////////////////////////////////////////////
/////////// Helper functions ///////////////////////
////////////////////////////////////////////////////

const register = async (
  authToken: string,
  userInfo: PersonUserInfo | BusinessUserInfo
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/register`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...userInfo }),
      credentials: "include",
    }
  );
  const data = await response.json();
  if (response.ok) {
    const authToken: string = data.id_token;
    return authToken;
  } else {
    throw response;
  }
};

////////////////////////////////////////////////////
////////////////// Component ///////////////////////
////////////////////////////////////////////////////

export default function Registration() {
  const location = useLocation();
  const snackbar = useSnackbar();

  const { auth, paymentDetails } =
    useOutletContext<PaymentRequestOutletContext>();

  const [loading, setLoading] = useState(false);

  if (!auth.user) {
    return <Navigate to={`/login${location.search}`} replace />;
  }
  if (auth.user.status === "REGISTERED") {
    return <Navigate to={`/vincular-cuenta${location.search}`} replace />;
  }

  const onContinue = async (userInfo: PersonUserInfo | BusinessUserInfo) => {
    setLoading(true);
    try {
      auth.setToken(await register(auth.token!, userInfo));
      setTimeout(() => setLoading(false), 100);
    } catch (err: any) {
      console.error(err);
      snackbar.showMessage("Hubo un error. Inténtalo más tarde.");
      if ("status" in err && err.status === 401) auth.setToken(null);
      setLoading(false);
    }
  };
  return (
    <>
      <CustomSteppers
        steps={["Datos", "Vincular", "Confirmar"]}
        activeStep={0}
      />
      <RegistrationTemplate
        onContinue={onContinue}
        loading={loading}
        defaultEntityType={paymentDetails.user_information?.user_type}
        defaultFirstName={paymentDetails.user_information?.first_name}
        defaultLastName={paymentDetails.user_information?.last_name}
        defaultBusinessName={paymentDetails.user_information?.business_name}
        defaultEmail={paymentDetails.user_information?.email}
        defaultDocumentType={paymentDetails.user_information?.document_type}
        defaultDocumentNumber={paymentDetails.user_information?.document_number}
      />
    </>
  );
}
