import { CustomButton } from "../../elements/CustomButton";
import { CustomTextField } from "../../elements/CustomTextField";
import { CustomInputSelect } from "../../elements/CustomInputSelect";
import { BlockTitles } from "../../elements/BlockTitles";
import { isValidPhoneNumber } from "react-phone-number-input";
import { RadioOptionsBox } from "../../elements/RadioOptionsBox";

interface VerifyNumberProps {
  countryCode: string;
  setCountryCode: (countryCode: string) => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  otpDeliveryMethod: "WHATSAPP" | "SMS";
  setOtpDeliveryMethod: (otpDeliveryMethod: "WHATSAPP" | "SMS") => void;
  onContinue: () => void;
  loading?: boolean;
}

export const VerifyNumber = (props: VerifyNumberProps) => {
  return (
    <div>
      <BlockTitles
        title="Ingresa tu número de teléfono"
        subtitle="No lo compartiremos. Solo lo usaremos para verificar tu identidad y confirmar tu pago."
      />

      <div className="border-line">
        <div className="border-t-4 my-3 py-3 w-full">
          <div className="py-4">
            <div className="mt-4 flex flex-row">
              <div className="w-[120px] mr-4">
                <CustomInputSelect
                  label="País"
                  fullWidth={true}
                  value={props.countryCode}
                  onChange={(e) => props.setCountryCode(e.target.value)}
                  documentTypes={[
                    { value: "+57", label: "+57" },
                    { value: "+1", label: "+1" },
                    { value: "+34", label: "+34" },
                    { value: "+51", label: "+51" },
                    { value: "+52", label: "+52" },
                    { value: "+91", label: "+91" },
                    { value: "+502", label: "+502" },
                    { value: "+380", label: "+380" },
                  ]}
                />
              </div>

              <div className="w-full">
                <CustomTextField
                  label="Teléfono"
                  inputProps={{ inputMode: "numeric" }}
                  fullWidth={true}
                  value={props.phoneNumber}
                  onChange={(e) =>
                    /^\d{0,10}$/.test(e.target.value) &&
                    props.setPhoneNumber(e.target.value)
                  }
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2 mt-4">
              <RadioOptionsBox
                title="WhatsApp"
                checked={props.otpDeliveryMethod === "WHATSAPP"}
                onClick={() => props.setOtpDeliveryMethod("WHATSAPP")}
              />
              <RadioOptionsBox
                title="SMS"
                checked={props.otpDeliveryMethod === "SMS"}
                onClick={() => props.setOtpDeliveryMethod("SMS")}
              />
            </div>

            <div className="text-[14px] lg:text-[16px] font-[400] text-[#666666]  mt-2">
              Esta selección solo aplica para verificar tu identidad. La
              confirmación de pago se envía a WhatsApp.
            </div>
          </div>

          <div className="mt-[16px]">
            <CustomButton
              label="Continuar"
              disabled={
                !isValidPhoneNumber(`${props.countryCode}${props.phoneNumber}`)
              }
              fullWidth={true}
              loading={props.loading}
              onClick={props.onContinue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
