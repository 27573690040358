import { useEffect } from "react";
import useSWR from "swr";
import { Auth, PaymentDetails } from "../layouts/PaymentRequestDetailsLayout";
import { Merchant } from "./useMerchant";

export type Discount = {
  credits: number;
};

const useCredits = (
  auth: Auth,
  paymentDetails: PaymentDetails | null,
  merchant: Merchant | undefined
) => {
  const { data, error, isLoading, mutate } = useSWR<Discount>(
    () =>
      auth.token &&
      auth.user?.status === "REGISTERED" &&
      paymentDetails &&
      merchant && [
        `${process.env.REACT_APP_API_URL}/rewards/credits?merchant=${merchant.id}&amount=${paymentDetails.amount_cents}`,
        auth,
      ]
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return { discount: data, discountLoading: isLoading, mutateDiscount: mutate };
};

export default useCredits;
