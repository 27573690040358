import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";

export type Globals = {
  status: "AVAILABLE" | "DOWN";
};

const useGlobals = () => {
  const { data, error, isLoading } = useSWRImmutable<Globals>([
    `${process.env.REACT_APP_API_URL}/globals`,
  ]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return { globals: data, globalsLoading: isLoading };
};

export default useGlobals;
