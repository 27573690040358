import { useCallback, useState } from "react";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import { useSnackbar } from "material-ui-snackbar-provider";
import { VerifyCode } from "../components/templates/login/VerifyCode";
import { VerifyNumber } from "../components/templates/login/VerifyNumber";
import { PaymentRequestOutletContext } from "../layouts/PaymentRequestDetailsLayout";
import { parsePhoneNumber } from "react-phone-number-input";

export default function Login() {
  const location = useLocation();
  const snackbar = useSnackbar();

  const { auth, paymentDetails } =
    useOutletContext<PaymentRequestOutletContext>();

  const phoneNumberString = paymentDetails.user_information?.phone_number;
  const phoneNumber = phoneNumberString
    ? parsePhoneNumber(phoneNumberString)
    : undefined;

  const [countryCode, setCountryCode] = useState<string>(
    phoneNumber ? `+${phoneNumber.countryCallingCode}` : "+57"
  );
  const [nationalNumber, setNationalNumber] = useState<string>(
    phoneNumber ? `${phoneNumber?.nationalNumber}` : ""
  );
  const [otpDeliveryMethod, setOtpDeliveryMethod] = useState<
    "WHATSAPP" | "SMS"
  >("WHATSAPP");
  const [mfaLoading, setMfaLoading] = useState<boolean>(false);
  const [codeSent, setCodeSent] = useState<boolean>(false);

  // const [pinCode, setPinCode] = useState<string>("");
  const [mfaCode, setMfaCode] = useState<string>("");
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const verifyNumber = useCallback(() => {
    const verifyNumber = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/mfa`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: `${countryCode}${nationalNumber}`,
            otpDeliveryMethod,
          }),
          credentials: "include",
        }
      );
      if (response.status === 200) {
        setCodeSent(true);
        setTimeout(() => setMfaLoading(false), 20000);
      } else {
        console.error(response);
        snackbar.showMessage("Hubo un error. Inténtalo más tarde.");
        setMfaLoading(false);
      }
    };

    setMfaLoading(true);
    verifyNumber().catch((err) => {
      console.error(err);
      setMfaLoading(false);
    });
  }, [countryCode, nationalNumber, otpDeliveryMethod, snackbar]);

  const login = useCallback(() => {
    const login = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // pin: pinCode,
            code: mfaCode,
          }),
          credentials: "include",
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        auth.setToken(data.id_token);
        setTimeout(() => setLoginLoading(false), 100);
      } else {
        console.error(response);
        setMfaCode("");
        snackbar.showMessage(
          "El código es incorrecto o ha expirado. Intenta reenviarlo."
        );
        setLoginLoading(false);
      }
    };

    setLoginLoading(true);
    login().catch((err) => {
      console.error(err);
      setLoginLoading(false);
    });
  }, [mfaCode, auth, snackbar]);

  if (auth.token) {
    return <Navigate to={`/${location.search}`} replace />;
  }

  if (!codeSent) {
    return (
      <VerifyNumber
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        phoneNumber={nationalNumber}
        setPhoneNumber={setNationalNumber}
        otpDeliveryMethod={otpDeliveryMethod}
        setOtpDeliveryMethod={setOtpDeliveryMethod}
        onContinue={verifyNumber}
        loading={mfaLoading}
      />
    );
  }

  return (
    <VerifyCode
      phone_number={`${countryCode}${nationalNumber}`}
      // pinCode={pinCode}
      // setPinCode={setPinCode}
      mfaCode={mfaCode}
      setMfaCode={setMfaCode}
      onContinue={login}
      loading={loginLoading}
      resendEnabled={!mfaLoading}
      resendCode={verifyNumber}
    />
  );
}
