import { useEffect } from "react";
import useSWR from "swr";
import { Bank } from "./useBanks";

export type BankWithPaymentRail =
  | (Bank & {
      payment_rail: "bancolombia_token" | "nequi_token";
      acceptance_token: string;
    })
  | (Bank & {
      payment_rail: "pse";
      pse_code: string;
    })
  | (Bank & {
      payment_rail: "daviplata_api" | "ach";
    });

const useBank = (bankId: string | null) => {
  const { data, error, isLoading } = useSWR<BankWithPaymentRail>(
    () => bankId && [`${process.env.REACT_APP_API_URL}/banks/${bankId}`]
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return { bank: data, bankLoading: isLoading };
};

export default useBank;
