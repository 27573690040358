import { useState } from "react";
import { Merchant } from "../../hooks/useMerchant";
import { BlockTitles } from "../elements/BlockTitles";
import { CustomButton } from "../elements/CustomButton";
import { CustomTextField } from "../elements/CustomTextField";

interface SetPriceProps {
  merchant: Merchant;
  onContinue: (price: string) => void;
}

export default function SetPrice(props: SetPriceProps) {
  const [price, setPrice] = useState("");

  return (
    <div>
      {props.merchant.logo_url && (
        <div className="mb-4 h-20 lg:h-[100px]">
          <img className="h-full" src={props.merchant.logo_url} alt="logo" />
        </div>
      )}

      <BlockTitles
        title="Ingresa el valor a pagar"
        subtitle={`${props.merchant.name} será notificado de tu pago una vez completado.`}
      />

      <div className="border-line" />

      <div className="border-t-4 py-8 my-4 w-full">
        <div className="flex flex-col">
          <CustomTextField
            label="Precio"
            inputProps={{ inputMode: "numeric" }}
            fullWidth={true}
            value={price}
            onChange={(e) => {
              const newValue = e.target.value
                .replace(/[^0-9]/g, "")
                .slice(0, 7);
              if (/^-?\d*$/.test(newValue)) {
                setPrice(
                  newValue === ""
                    ? ""
                    : `$ ${Number(newValue).toLocaleString("es-CO")}`
                );
              }
            }}
          />
        </div>

        <div className="flex flex-col mt-[24px]">
          <CustomButton
            label="Continuar"
            disabled={
              Number(price.replace(/[^0-9]/g, "")) < 5000 ||
              Number(price.replace(/[^0-9]/g, "")) > 12000000
            }
            fullWidth={true}
            onClick={() => props.onContinue(price.replace(/[^0-9]/g, ""))}
          />
        </div>
      </div>
    </div>
  );
}
