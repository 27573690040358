import { StatusMessage } from "../components/elements/StatusMessage";
import { SingleLayout } from "../layouts/SingleLayout";

export default function NotFound() {
  return (
    <SingleLayout>
      <StatusMessage
        title="Lo sentimos!"
        subtitle="Hay un error en el link de pago. Por favor revisa con tu comercio que te entregó el link."
        success={false}
      />
    </SingleLayout>
  );
}
