import { BlockTitles } from "../../../elements/BlockTitles";
import { CustomButton } from "../../../elements/CustomButton";
import { Divider } from "../../../elements/Divider";

type ConfirmPseProps = {
  bankName: string;
  onContinue: () => void;
  loading?: boolean;
};

export const ConfirmPse = ({
  bankName,
  onContinue,
  loading,
}: ConfirmPseProps) => {
  return (
    <div className="my-6">
      <BlockTitles
        title="Tus transacciones serán procesadas por PSE"
        subtitle={`La conexión directa a ${bankName} no está activa por el momento. No podrás usar la función de pago a un click.`}
      />
      <div className="my-6">
        <Divider />
      </div>

      <CustomButton
        label="Continuar"
        disabled={false}
        fullWidth={true}
        onClick={onContinue}
        loading={loading}
      />
    </div>
  );
};
