import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedButton = styled(LoadingButton)<ButtonProps>(() => ({
  "&.MuiButton-root": {
    backgroundColor: "#5D26C5",
    textTransform: "initial",
    height: 56,
    fontWeight: "400",
    borderRadius: 100,
    fontSize: 20,
    "&.MuiButton-text": {
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#3c0d94",
        color: "#ffffff",
      },
      "&:active": {
        backgroundColor: "#5D26C5",
        color: "#ffffff",
      },
      "&.Mui-disabled": {
        color: "#454545",
        background: "#E8E8E8",
      },
    },
  },
}));

interface Props {
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | (() => void);
  testId?: string;
  className?: string;
  type?: "submit" | "reset" | "button";
  id?: string;
  loading?: boolean;
}

export const CustomButton = (props: Props) => {
  const {
    label,
    disabled,
    fullWidth,
    style,
    onClick,
    testId,
    className,
    type,
    id,
    loading,
  } = props;

  return (
    <CustomizedButton
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      className={className}
      type={type}
      id={id}
      disableRipple
      style={style}
      data-testid={testId}
      loading={loading}
      loadingPosition="center"
    >
      {loading ? "" : label}
    </CustomizedButton>
  );
};
