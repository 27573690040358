import React, { PropsWithChildren } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Divider } from "./Divider";

interface LoadingTemplateProps {
  message: string;
  footerText?: string;
}

export const Loading = ({
  message,
  footerText,
  children,
}: PropsWithChildren<LoadingTemplateProps>) => {
  return (
    <div>
      <AiOutlineLoading3Quarters className="text-[#5D26C5] text-5xl animate-spin" />

      <div className=" w-full mt-4 pb-4">
        <div className=" text-[25px] lg:text-[46px] leading-8 lg:leading-[3rem] font-[400] text-[#000000]">
          {message}
        </div>
      </div>
      <Divider />
      <div className="py-4">
        <React.Fragment>{children}</React.Fragment>
      </div>
      <div className="text-[20px] py-4 lg:text-[16px] font-[400] text-[#666666]">
        {footerText || "Rápido. Seguro. Sin deuda."}
      </div>
    </div>
  );
};
