import { BlockTitles } from "../../../elements/BlockTitles";
import { CustomInputSelect } from "../../../elements/CustomInputSelect";
import { CustomTextField } from "../../../elements/CustomTextField";
import { useState } from "react";
import { CustomButton } from "../../../elements/CustomButton";
import { CustomCheckbox } from "../../../elements/CustomCheckbox";
import { isValidPhoneNumber } from "react-phone-number-input";

interface ConfirmDaviplataApiProps {
  onContinue: (
    documentType: string,
    documentNumber: string,
    phoneNumber: string
  ) => void;
  loading: boolean;
  default_document_type: string;
  default_document_number: string;
  default_phone_number: string;
}

export const ConfirmDaviplataApi = ({
  onContinue,
  loading,
  default_document_type,
  default_document_number,
  default_phone_number,
}: ConfirmDaviplataApiProps) => {
  const [documentType, setDocumentType] = useState(default_document_type);
  const [documentNumber, setDocumentNumber] = useState(default_document_number);
  const [phoneNumber, setPhoneNumber] = useState(
    default_phone_number?.startsWith("+57")
      ? default_phone_number?.replace("+57", "")
      : ""
  );
  const [terms, setTerms] = useState(false);

  return (
    <div className="my-6">
      <BlockTitles
        title="Confirma tu cuenta Daviplata"
        subtitle="Mandaremos un mensaje de verificación a tu celular para confirmar tu cuenta Daviplata."
      />

      <div className="my-6 flex space-x-4 whitespace-normal">
        <CustomInputSelect
          label="Doc."
          value={documentType}
          documentTypes={[{ value: "CC" }, { value: "CE" }, { value: "TI" }]}
          style={{ width: "120px" }}
          onChange={(e) => setDocumentType(e.target.value)}
        />

        <CustomTextField
          label="Número de identificación"
          fullWidth={true}
          value={documentNumber}
          onChange={(e) =>
            setDocumentNumber(e.target.value.replace(/[^0-9]/g, ""))
          }
        />
      </div>

      <div className="my-6 flex space-x-4 whitespace-normal">
        <CustomInputSelect
          label="Pais"
          value="+57"
          documentTypes={[{ value: "+57", label: "+57" }]}
          disabled={true}
          style={{ width: "120px" }}
        />

        <CustomTextField
          label="Teléfono"
          autoComplete="tel-national"
          fullWidth={true}
          value={phoneNumber}
          onChange={(e) =>
            /^\d{0,10}$/.test(e.target.value) && setPhoneNumber(e.target.value)
          }
        />
      </div>

      <div className="my-4 text-[16px] text-[#454545]">
        Esta es la cuenta Daviplata de la cual será debitada el dinero en el
        futuro. Verifica que sea la correcta.
      </div>

      <CustomCheckbox
        label="Autorizo almacenar de forma segura la información relacionada con mi medio de pago DaviPlata para futuras compras."
        value={terms}
        onChange={() => setTerms(!terms)}
      />

      <CustomButton
        label="Confirmar"
        onClick={() => onContinue(documentType, documentNumber, phoneNumber)}
        fullWidth={true}
        disabled={!isValidPhoneNumber(`+57${phoneNumber}`) || !terms}
        loading={loading}
      />
    </div>
  );
};
