import Checkbox from "@mui/material/Checkbox";
import { ReactElement } from "react";

interface props {
  label: ReactElement | string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
}

export const CustomCheckbox = ({ label, onChange, value }: props) => {
  return (
    <div>
      <div className="flex gap-5 pb-6">
        <Checkbox
          id="label"
          onChange={onChange}
          value={value}
          sx={{
            color: "#666666",
            "&.Mui-checked": {
              color: "#5D26C5",
            },
          }}
        />
        <label
          htmlFor="label"
          className={`${value ? "text-black " : "text-gray-800"} text-[16px] `}
        >
          {label}
        </label>
      </div>
    </div>
  );
};
