import { useEffect } from "react";
import useSWR from "swr";
import { Auth } from "../layouts/PaymentRequestDetailsLayout";
import { Transaction } from "./useTransaction";
import { Merchant } from "./useMerchant";

export type Coins = {
  coins: number;
};

const useCoins = (
  auth: Auth,
  transaction: Transaction | undefined,
  merchant: Merchant | undefined
) => {
  const { data, error, isLoading, mutate } = useSWR<Coins>(
    () =>
      auth.token &&
      auth.user.status === "REGISTERED" &&
      merchant?.rewards_enabled &&
      transaction?.status === "APPROVED" && [
        `${process.env.REACT_APP_API_URL}/rewards/coins`,
        auth,
      ]
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return {
    coins: data?.coins,
    coinsLoading: isLoading,
    mutateCoins: mutate,
  };
};

export default useCoins;
