import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";

export type Merchant = {
  status: "AVAILABLE" | "DISABLED";
  id: string;
  name: string;
  logo_url: string;
  default_redirect_url?: string;
  rewards_enabled?: boolean;
  pse_disabled: boolean;
  ach_disabled: boolean;
};

const useMerchant = (merchantId: string | null) => {
  const { data, error, isLoading } = useSWRImmutable<Merchant>(
    () =>
      merchantId && [`${process.env.REACT_APP_API_URL}/merchants/${merchantId}`]
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return { merchant: data, merchantLoading: isLoading };
};

export default useMerchant;
