import { Outlet, useSearchParams } from "react-router-dom";
import { Loading } from "../components/elements/Loading";
import { PaymentRequestDetails } from "../components/templates/PaymentRequestDetails";
import { DualLayout } from "./DualLayout";
import { SingleLayout } from "./SingleLayout";
import { StatusMessage } from "../components/elements/StatusMessage";
import { useCallback, useEffect, useMemo, useState } from "react";
import jwtDecode from "jwt-decode";
import useTransaction, { Transaction } from "../hooks/useTransaction";
import useSubscription, { Subscription } from "../hooks/useSubscription";
import useCredits from "../hooks/useCredits";
import useMerchant from "../hooks/useMerchant";
import { H } from "highlight.run";
import SetPrice from "../components/templates/SetPrice";
import useGlobals from "../hooks/useGlobals";

export type UserInformation = {
  phone_number?: string;
  email?: string;
  document_type?: "CC" | "CE" | "TI" | "NIT";
  document_number?: string;
  user_type?: "natural" | "legal";
  first_name?: string;
  last_name?: string;
  business_name?: string;
};

export type User =
  | {
      status: "PENDING";
      id: string;
      phone: string;
    }
  | {
      status: "REGISTERED";
      user_type: "PERSON";
      id: string;
      phone: string;
      email: string;
      first_name: string;
      last_name: string;
      document_type: string;
      document_number: string;
    }
  | {
      status: "REGISTERED";
      user_type: "BUSINESS";
      id: string;
      phone: string;
      email: string;
      business_name: string;
      document_type: string;
      document_number: string;
    };

// decode user from jwt token payload
export const getUserFromToken = (
  token: string,
  setAuth: (token: string | null) => void
): User | null => {
  try {
    const {
      "custom:status": status,
      sub: id,
      phone_number: phone,
      email,
      given_name: first_name,
      family_name: last_name,
      "custom:document_type": document_type,
      "custom:document_number": document_number,
      name: business_name,
    } = jwtDecode<any>(token);
    return {
      status,
      id,
      phone,
      email,
      first_name,
      last_name,
      document_type,
      document_number,
      business_name,
      user_type: business_name ? "BUSINESS" : "PERSON",
    };
  } catch (err) {
    console.error(err);
    setAuth(null);
    return null;
  }
};

export type PaymentRequestOutletContext = {
  paymentDetails: PaymentDetails;
  auth: Auth;
};

export type PaymentDetails =
  | {
      transaction: string;
      merchant: string;
      merchant_display_name: string | undefined;
      user_information: UserInformation | undefined;
      amount_cents: number;
      description: undefined;
      subscription: undefined;
      interval: undefined;
      interval_unit: undefined;
      count: undefined;
    }
  | {
      transaction: undefined;
      merchant: string;
      merchant_display_name: string | undefined;
      user_information: undefined;
      amount_cents: number;
      description?: string;
      subscription: undefined;
      interval: undefined;
      interval_unit: undefined;
      count: undefined;
    }
  | {
      transaction: undefined;
      merchant: string;
      merchant_display_name: string | undefined;
      user_information: UserInformation | undefined;
      amount_cents: number;
      description?: string;
      subscription: string;
      interval: number;
      interval_unit: string;
      count?: number;
    };
export type Auth = { setToken: (authToken: string | null) => void } & (
  | { token: string; user: User }
  | { token: null; user: null }
);

const getPaymentDetails = (
  transaction: Transaction | null,
  subscription: Subscription | null,
  merchant: string | null,
  amount: string | null,
  description: string | null
): PaymentDetails | null => {
  if (transaction)
    return {
      merchant: transaction.merchant,
      merchant_display_name: transaction.merchant_display_name,
      user_information: transaction.user_information,
      amount_cents: transaction.amount_cents,
      transaction: transaction.id,
      description: undefined,
      subscription: undefined,
      interval: undefined,
      interval_unit: undefined,
      count: undefined,
    };
  if (subscription)
    return {
      subscription: subscription.id,
      merchant: subscription.merchant,
      merchant_display_name: undefined,
      user_information: subscription.user_information,
      amount_cents: subscription.amount_cents,
      transaction: undefined,
      description: undefined,
      interval: subscription.interval,
      interval_unit: subscription.interval_unit,
      count: subscription.count,
    };
  if (
    merchant &&
    amount &&
    parseInt(amount) >= 5000 &&
    parseInt(amount) <= 120_000_000
  ) {
    return {
      merchant,
      merchant_display_name: undefined,
      user_information: undefined,
      amount_cents: parseInt(amount) * 100,
      description: description ? decodeURI(description) : undefined,
      transaction: undefined,
      subscription: undefined,
      interval: undefined,
      interval_unit: undefined,
      count: undefined,
    };
  }
  return null;
};

export const PaymentRequestDetailsLayout = () => {
  /////// AUTHENTICATION //////
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [authLoading, setAuthLoading] = useState(true);
  const setAuth = useCallback((token: string | null) => {
    setAuthToken(token);
    if (!token) {
      localStorage.removeItem("token");
    } else {
      localStorage.setItem("token", token);
    }
  }, []);
  const user = useMemo(
    () => (authToken ? getUserFromToken(authToken, setAuth) : null),
    [authToken, setAuth]
  );

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/verify-login`,
        {
          credentials: "include",
          method: "post",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const auth = response.status === 200 ? await response.json() : null;
      setAuth(auth?.id_token || null);
    };

    fetchUser()
      .catch((err) => console.error(err))
      .finally(() => {
        setTimeout(() => {
          setAuthLoading(false);
        }, 100);
      });
  }, [setAuth]);

  const auth: Auth = {
    setToken: setAuth,
    ...(user ? { token: authToken!, user } : { token: null, user: null }),
  };

  useEffect(() => {
    if (user && user.status === "REGISTERED") {
      H.identify(user.id, {
        name:
          user.user_type === "BUSINESS"
            ? user.business_name
            : `${user.first_name} ${user.last_name}`,
        phone: user.phone,
        email: user.email,
      });
    }
  }, [user]);

  /////// PAYMENT DETAILS //////
  const [searchParams, setSearchParams] = useSearchParams();
  const { transaction, transactionLoading } = useTransaction(
    auth,
    searchParams.get("transaccion")
  );
  const { subscription, subscriptionLoading } = useSubscription(
    auth,
    searchParams.get("suscripcion")
  );
  const paymentDetails = getPaymentDetails(
    transaction || null,
    subscription || null,
    searchParams.get("comercio"),
    searchParams.get("precio"),
    searchParams.get("descripcion")
  );

  const setPrice = useCallback(
    (price: string) => {
      searchParams.append("precio", price);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  /////// OTHERS //////
  const { globals, globalsLoading } = useGlobals();
  const { merchant, merchantLoading } = useMerchant(
    paymentDetails?.merchant || searchParams.get("comercio") || null
  );
  const { discount, discountLoading } = useCredits(
    auth,
    paymentDetails,
    merchant
  );

  if (
    globalsLoading ||
    authLoading ||
    transactionLoading ||
    merchantLoading ||
    discountLoading ||
    subscriptionLoading
  )
    return (
      <SingleLayout>
        <Loading message="Cargando..." />
      </SingleLayout>
    );

  if (merchant?.status === "DISABLED")
    return (
      <SingleLayout>
        <StatusMessage
          title="Lo sentimos!"
          subtitle="Pagos por este comercio no están disponibles en este momento. Intenta más tarde."
          success={false}
        />
      </SingleLayout>
    );

  if (!globals || globals.status === "DOWN") {
    return (
      <SingleLayout>
        <StatusMessage
          title="Bancolombia, Nequi y PSE estan presentando fallos"
          subtitle="Aunque no es nuestra culpa, ¡nos disculpamos! Cuando se solucione podrás realizar tu transacción. Intenta de nuevo más tarde."
          success={false}
        />
      </SingleLayout>
    );
  }

  if (!paymentDetails && merchant && !searchParams.get("precio"))
    return (
      <SingleLayout>
        <SetPrice merchant={merchant} onContinue={setPrice} />
      </SingleLayout>
    );

  if (!paymentDetails || !merchant) {
    return (
      <SingleLayout>
        <StatusMessage
          title="Lo sentimos!"
          subtitle="Hay un error en el link de pago. Por favor revisa con tu comercio que te entregó el link."
          success={false}
        />
      </SingleLayout>
    );
  }

  const paymentDetailsComponent = (
    <PaymentRequestDetails
      authToken={authToken}
      setAuthToken={setAuth}
      merchant={merchant}
      merchant_display_name={paymentDetails.merchant_display_name}
      subtotal={paymentDetails.amount_cents}
      discount={
        ((!transaction || transaction.status === "PENDING") &&
        merchant.rewards_enabled
          ? discount?.credits
          : transaction?.credits) || 0
      }
      subscription={subscription}
    />
  );

  return (
    <DualLayout
      leftSlot={paymentDetailsComponent}
      showBanner={false && !!merchant?.rewards_enabled}
    >
      <Outlet context={{ auth, paymentDetails }} />
    </DualLayout>
  );
};
