import { useState } from "react";
import { BlockTitles } from "../../../elements/BlockTitles";
import { CustomButton } from "../../../elements/CustomButton";
import { CustomCheckbox } from "../../../elements/CustomCheckbox";

interface ConfirmBancolombiaTokenProps {
  onContinue: () => void;
  loading: boolean;
  terms_link: string;
}

export const ConfirmBancolombiaToken = ({
  onContinue,
  loading,
  terms_link,
}: ConfirmBancolombiaTokenProps) => {
  const [terms, setTerms] = useState(false);

  return (
    <div className="my-6">
      <BlockTitles
        title="Termina la vinculación de tu cuenta"
        subtitle="Te llevaremos a Bancolombia para finalizar el proceso de vinculación de tu cuenta de forma segura. Solo debitaremos de tu cuenta con tu permiso explícito cada vez que vayas a realizar un pago por Palomma."
      />

      <div className="my-4" />

      <CustomCheckbox
        label={
          <>
            {"Acepto los "}{" "}
            <a href={terms_link} target="_blank" rel="noopener noreferrer">
              <u>términos y condiciones y política de privacidad</u>
            </a>
          </>
        }
        value={terms}
        onChange={() => setTerms(!terms)}
      />

      <CustomButton
        label="Confirmar"
        onClick={onContinue}
        fullWidth={true}
        disabled={!terms}
        loading={loading}
      />
    </div>
  );
};
