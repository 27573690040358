import { BlockTitles } from "../../../elements/BlockTitles";
import { CustomInputSelect } from "../../../elements/CustomInputSelect";
import { CustomTextField } from "../../../elements/CustomTextField";
import { CustomCheckbox } from "../../../elements/CustomCheckbox";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useState } from "react";
import { CustomButton } from "../../../elements/CustomButton";

interface ConfirmNequiTokenProps {
  onContinue: (phoneNumber: string) => void;
  loading: boolean;
  terms_link: string;
  default_phone?: string;
}

export const ConfirmNequiToken = ({
  onContinue,
  loading,
  terms_link,
  default_phone,
}: ConfirmNequiTokenProps) => {
  const [phoneNumber, setPhoneNumber] = useState(
    default_phone?.startsWith("+57") ? default_phone?.replace("+57", "") : ""
  );
  const [terms, setTerms] = useState(false);

  return (
    <div className="my-6">
      <BlockTitles
        title="Confirma tu número Nequi"
        subtitle="Ahora confirma mediante tu app de Nequi para finalizar el proceso de vinculación"
      />

      <div className="my-6 flex space-x-4 whitespace-normal">
        <CustomInputSelect
          label="Pais"
          value="+57"
          documentTypes={[{ value: "+57", label: "+57" }]}
          disabled={true}
          style={{ width: "120px" }}
        />

        <CustomTextField
          label="Teléfono"
          autoComplete="tel-national"
          fullWidth={true}
          value={phoneNumber}
          onChange={(e) =>
            /^\d{0,10}$/.test(e.target.value) && setPhoneNumber(e.target.value)
          }
        />
      </div>

      <div className="my-4 text-[16px] text-[#454545]">
        Este es el número de Nequi del cual será debitado el dinero en el
        futuro. Verifica que es el correcto.
      </div>

      <CustomCheckbox
        label={
          <>
            {"Acepto los "}{" "}
            <a href={terms_link} target="_blank" rel="noopener noreferrer">
              <u>términos y condiciones y política de privacidad</u>
            </a>
          </>
        }
        value={terms}
        onChange={() => setTerms(!terms)}
      />

      <CustomButton
        label="Confirmar"
        onClick={() => onContinue(phoneNumber)}
        fullWidth={true}
        disabled={!isValidPhoneNumber(`+57${phoneNumber}`) || !terms}
        loading={loading}
      />
    </div>
  );
};
