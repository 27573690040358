import { useSnackbar } from "material-ui-snackbar-provider";
import { ConfirmPaymentAccount } from "../components/templates/home/ConfirmPaymentAccount";
import {
  Navigate,
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import usePaymentAccount from "../hooks/usePaymentAccount";
import { Loading } from "../components/elements/Loading";
import { StatusMessage } from "../components/elements/StatusMessage";
import {
  Auth,
  PaymentDetails,
  PaymentRequestOutletContext,
} from "../layouts/PaymentRequestDetailsLayout";
import usePaymentAccounts, {
  PaymentAccount,
} from "../hooks/usePaymentAccounts";
import useCredits, { Discount } from "../hooks/useCredits";
import useCoins from "../hooks/useCoins";
import useTransaction, { Transaction } from "../hooks/useTransaction";
import useMerchant from "../hooks/useMerchant";
import { useState } from "react";
import useSubscription from "../hooks/useSubscription";
import jwtDecode from "jwt-decode";
import { RewardsCarousel } from "../components/elements/RewardsCarousel";
import useBank from "../hooks/useBank";
import useBanks from "../hooks/useBanks";

////////////////////////////////////////////////////
/////////// Helper functions ///////////////////////
////////////////////////////////////////////////////

const processTransactionRequest = async (
  auth: Auth,
  paymentDetails: PaymentDetails,
  paymentAccount: PaymentAccount,
  discount: Discount | null,
  railSpecificBody: { [k: string]: any }
) => {
  const response = paymentDetails.transaction
    ? await fetch(
        `${process.env.REACT_APP_API_URL}/transactions/${paymentDetails.transaction}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          method: "PUT",
          body: JSON.stringify({
            payment_account: paymentAccount.id,
            ...railSpecificBody,
            credits: discount ? discount.credits : null,
          }),
        }
      )
    : await fetch(`${process.env.REACT_APP_API_URL}/transactions`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          amount_cents: paymentDetails.amount_cents,
          merchant: paymentDetails.merchant,
          description: paymentDetails.description,
          payment_account: paymentAccount.id,
          ...railSpecificBody,
          credits: discount ? discount.credits : null,
        }),
      });
  if (response.ok) {
    const transaction: Transaction = await response.json();
    return transaction;
  } else {
    throw response;
  }
};

const deletePaymentAccountRequest = async (
  auth: Auth,
  paymentAccountId: string
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/payment-accounts/${paymentAccountId}`,
    {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    }
  );
  // await new Promise((resolve) => {
  //   setTimeout(resolve, 1000);
  // });

  if (!response.ok) throw response;

  return [];
};

////////////////////////////////////////////////////
////////////////// Component ///////////////////////
////////////////////////////////////////////////////
const insufficientFundsMessages = new Set([
  "NOT_ENOUGH_FUNDS",
  "¡Ups! No tienes suficiente plata en tu disponible.",
  "Fondos insuficientes",
]);

export default function Home() {
  const location = useLocation();
  const snackbar = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const [processTransactionLoading, setProcessTransactionLoading] =
    useState(false);
  const [processSubscriptionLoading, setProcessSubscriptionLoading] =
    useState(false);

  const { auth, paymentDetails } =
    useOutletContext<PaymentRequestOutletContext>();

  const { merchant, merchantLoading } = useMerchant(paymentDetails.merchant);

  const { banks, banksLoading } = useBanks();

  const { paymentAccounts, paymentAccountsLoading, mutatePaymentAccounts } =
    usePaymentAccounts(
      auth,
      (paymentAccount) =>
        !(
          (merchant?.pse_disabled && paymentAccount.payment_rail === "pse") ||
          (merchant?.ach_disabled && paymentAccount.payment_rail === "ach") ||
          (paymentAccount.payment_rail === "bancolombia_token" &&
            paymentDetails.amount_cents > 100000000) ||
          (paymentDetails.subscription
            ? paymentAccount.payment_rail === "pse"
            : paymentAccount.payment_rail === "ach") ||
          banks
            ?.find((bank) => bank.id === paymentAccount.bank)
            ?.rails_disabled_for_payments.includes(paymentAccount.payment_rail)
        )
    );
  const { paymentAccount, paymentAccountLoading } = usePaymentAccount(
    auth,
    paymentAccounts && paymentAccounts[0]?.id
  );
  const [changePaymentAccountLoading, setChangePaymentAccountLoading] =
    useState(false);

  const { bank, bankLoading } = useBank(paymentAccount?.bank || null);

  const { discount, discountLoading } = useCredits(
    auth,
    paymentDetails,
    merchant
  );

  const { transaction, transactionLoading, mutateTransaction } = useTransaction(
    auth,
    paymentDetails.transaction || null
  );

  const { coins, coinsLoading, mutateCoins } = useCoins(
    auth,
    transaction,
    merchant
  );

  const { subscription, mutateSubscription } = useSubscription(
    auth,
    paymentDetails.subscription || null
  );

  if (!auth.user) {
    return <Navigate to={`/login${location.search}`} replace />;
  }

  if (
    merchantLoading ||
    paymentAccountsLoading ||
    paymentAccountLoading ||
    changePaymentAccountLoading ||
    banksLoading ||
    bankLoading ||
    discountLoading ||
    transactionLoading ||
    coinsLoading
  )
    return <Loading message="Cargando..." />;

  if (!merchant)
    return (
      <StatusMessage
        success={false}
        title="Lo sentimos!"
        subtitle="Hubo un error cargando la información del comercio. Inténtalo otra vez."
      />
    );

  if (
    subscription?.status === "ACTIVE" ||
    subscription?.status === "PROCESSING"
  )
    return (
      <StatusMessage
        success={true}
        title={
          paymentAccount?.payment_rail === "ach"
            ? "Listo, la inscripción de tu cuenta para pagos con débito automático ha sido realizada con éxito."
            : "Tu suscripción está activa."
        }
        subtitle={
          paymentAccount?.payment_rail === "ach"
            ? "Te avisaremos cuando todo haya sido verificado. Esto puede tomar un par de días."
            : "Recibirás la confirmación de cada pago en tu Whatsapp."
        }
        button={{
          text: "Finalizar",
          onClick: () =>
            window.location.assign(
              subscription.redirect_url ||
                merchant.default_redirect_url ||
                "https://www.palomma.com"
            ),
        }}
      />
    );

  if (transaction?.status === "APPROVED")
    if (merchant.rewards_enabled) {
      return (
        <div>
          <StatusMessage
            success={true}
            title="¡Tu pago fue exitoso!"
            subtitle="Recibirás la confirmación del pago en tu Whatsapp."
            hideIcon={true}
          >
            {transaction.payment_rail_transaction_id && (
              <div className="text-[20px] mt-4  lg:text-[24px] font-[400] text-[#666666]">
                {`Valor: $${transaction.amount_cents.toLocaleString("es-CO")}`}
                {`Número de aprobación: ${transaction.payment_rail_transaction_id}`}
              </div>
            )}

            <RewardsCarousel
              authToken={auth.token}
              coins={coins || 0}
              mutateCoins={mutateCoins}
              handleDoneClick={() =>
                window.location.assign(
                  transaction.redirect_url ||
                    merchant.default_redirect_url ||
                    "https://www.palomma.com"
                )
              }
            />
          </StatusMessage>
        </div>
      );
    } else {
      return (
        <div>
          <StatusMessage
            success={true}
            title="¡Tu pago fue exitoso!"
            subtitle="Recibirás la confirmación del pago en tu Whatsapp."
            button={{
              text: "Finalizar",
              onClick: () =>
                window.location.assign(
                  transaction.redirect_url ||
                    merchant.default_redirect_url ||
                    "https://www.palomma.com"
                ),
            }}
          >
            {transaction.payment_rail_transaction_id && (
              <>
                <div className="text-[16px] mt-4 font-[400] text-[#666666]">
                  {`Valor: $${(transaction.amount_cents / 100).toLocaleString(
                    "es-CO"
                  )}`}
                </div>
                <div className="text-[16px] mb-4 font-[400] text-[#666666]">
                  {`Número de aprobación: ${transaction.payment_rail_transaction_id}`}
                </div>
              </>
            )}
          </StatusMessage>
        </div>
      );
    }

  if (transaction?.status === "DECLINED")
    return (
      <StatusMessage
        success={false}
        title={
          transaction.status_message &&
          insufficientFundsMessages.has(transaction.status_message)
            ? "Fondos insuficientes"
            : "Tu banco declinó el pago"
        }
        subtitle="Asegúrate que tengas suficiente dinero y vuelve a intentarlo, o comunícate con tu banco."
        button={{
          text: "Finalizar",
          onClick: () =>
            window.location.assign(
              transaction.redirect_url ||
                merchant.default_redirect_url ||
                "https://www.palomma.com"
            ),
        }}
      />
    );

  if (transaction?.status === "CANCELLED")
    return (
      <StatusMessage
        success={false}
        title="Tu pago ha sido cancelado"
        subtitle={`Este link ha expirado, o ha sido cancelado por ${
          transaction.merchant_display_name || merchant.name
        }. Pide otro link para finalizar tu pago.`}
        button={{
          text: "Finalizar",
          onClick: () =>
            window.location.assign(
              transaction.redirect_url ||
                merchant.default_redirect_url ||
                "https://www.palomma.com"
            ),
        }}
      />
    );
  if (transaction?.status === "ERROR")
    return (
      <StatusMessage
        success={false}
        title="Algo pasó con tu pago"
        subtitle="No te preocupes, ningún dinero ha salido de tu cuenta. Vuelve a intentar hacer tu pago."
        button={{
          text: "Finalizar",
          onClick: () =>
            window.location.assign(
              transaction.redirect_url ||
                merchant.default_redirect_url ||
                "https://www.palomma.com"
            ),
        }}
      />
    );

  if (transaction?.status === "PROCESSING") {
    if (transaction.payment_url && !searchParams.has("finalizado"))
      window.location.assign(transaction.payment_url);
    return (
      <Loading
        message="Estamos procesando el pago"
        footerText="Esto puede tardar varios segundos. Tu banco nos informará cuando el pago haya sido aprobado o rechazado."
      />
    );
  }

  if (auth.user.status === "PENDING")
    return <Navigate to={`/registro${location.search}`} />;
  if (paymentAccounts?.length === 0)
    return <Navigate to={`/vincular-cuenta${location.search}`} />;

  if (!paymentAccount || !bank)
    return (
      <StatusMessage
        success={false}
        title="Lo sentimos!"
        subtitle="No pudimos cargar tu información bancaria. Inténtalo más tarde."
      />
    );

  const processTransaction = async (railSpecificBody: { [k: string]: any }) => {
    setProcessTransactionLoading(true);
    try {
      const transaction = await processTransactionRequest(
        auth,
        paymentDetails,
        paymentAccount,
        discount || null,
        railSpecificBody
      );
      searchParams.delete("solicitud");
      searchParams.set("transaccion", transaction.id);
      setSearchParams(searchParams);
      mutateTransaction(transaction);
    } catch (err: any) {
      console.error(err);

      if ("status" in err && err.status === 401) auth.setToken(null);
      else if ("status" in err && err.status === 423)
        snackbar.showMessage(
          "PSE ha identificado una transacción en proceso. Vuelve a intentar en 60 minutos."
        );
      else snackbar.showMessage("Hubo un error. Inténtalo de nuevo.");
    }
    setProcessTransactionLoading(false);
  };

  const changeAccount = async () => {
    setChangePaymentAccountLoading(true);
    await deletePaymentAccountRequest(auth, paymentAccount.id);
    mutatePaymentAccounts(undefined);
    setChangePaymentAccountLoading(false);
  };

  const processSubscription = async () => {
    if (!paymentDetails.subscription) throw new Error("No subscription");
    setProcessSubscriptionLoading(true);
    await fetch(
      `${process.env.REACT_APP_API_URL}/subscriptions/${paymentDetails.subscription}`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          payment_account: paymentAccount.id,
        }),
      }
    );
    mutateSubscription(undefined);
    setProcessSubscriptionLoading(false);
  };

  switch (paymentAccount.payment_rail) {
    case "bancolombia_token":
    case "nequi_token":
    case "daviplata_api": {
      return (
        <ConfirmPaymentAccount
          bank={bank}
          paymentDetails={paymentDetails}
          paymentAccount={paymentAccount}
          merchantName={transaction?.merchant_display_name || merchant.name}
          processTransaction={() =>
            processTransaction({ payment_rail: paymentAccount.payment_rail })
          }
          processTransactionLoading={processTransactionLoading}
          subscription={paymentDetails.subscription}
          processSubscription={processSubscription}
          processSubscriptionLoading={processSubscriptionLoading}
          changeAccount={changeAccount}
          identificationsMatch={
            subscription
              ? !subscription.enforced_document_number ||
                !paymentAccount.affiliate?.document_number ||
                paymentAccount.affiliate.document_number ===
                  subscription.enforced_document_number
              : !transaction?.enforced_document_number ||
                !paymentAccount.affiliate?.document_number ||
                paymentAccount.affiliate.document_number ===
                  transaction.enforced_document_number
          }
        />
      );
    }
    case "ach": {
      return (
        <ConfirmPaymentAccount
          bank={bank}
          paymentDetails={paymentDetails}
          paymentAccount={paymentAccount}
          merchantName={transaction?.merchant_display_name || merchant.name}
          processTransaction={() =>
            processTransaction({
              payment_rail: paymentAccount.payment_rail,
            })
          }
          processTransactionLoading={processTransactionLoading}
          subscription={paymentDetails.subscription}
          processSubscription={processSubscription}
          processSubscriptionLoading={processSubscriptionLoading}
          changeAccount={changeAccount}
          identificationsMatch={
            subscription
              ? !subscription.enforced_document_number ||
                !paymentAccount.affiliate?.document_number ||
                paymentAccount.affiliate.document_number ===
                  subscription.enforced_document_number
              : !transaction?.enforced_document_number ||
                !paymentAccount.affiliate?.document_number ||
                paymentAccount.affiliate.document_number ===
                  transaction.enforced_document_number
          }
        />
      );
    }
    case "pse": {
      return (
        <ConfirmPaymentAccount
          bank={bank}
          paymentDetails={paymentDetails}
          paymentAccount={paymentAccount}
          merchantName={transaction?.merchant_display_name || merchant.name}
          processTransaction={() =>
            processTransaction({
              payment_rail: paymentAccount.payment_rail,
              acceptance_token: paymentAccount.acceptance_token,
            })
          }
          processTransactionLoading={processTransactionLoading}
          subscription={paymentDetails.subscription}
          processSubscription={processSubscription}
          processSubscriptionLoading={processSubscriptionLoading}
          changeAccount={changeAccount}
          terms={jwtDecode<any>(paymentAccount.acceptance_token).permalink}
          identificationsMatch={
            subscription
              ? !subscription.enforced_document_number ||
                !paymentAccount.affiliate?.document_number ||
                paymentAccount.affiliate.document_number ===
                  subscription.enforced_document_number
              : !transaction?.enforced_document_number ||
                !paymentAccount.affiliate?.document_number ||
                paymentAccount.affiliate.document_number ===
                  transaction.enforced_document_number
          }
        />
      );
    }
  }
}
