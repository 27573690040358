import { PropsWithChildren, ReactElement } from "react";
import { Outlet } from "react-router-dom";

interface props {
  leftSlot: ReactElement<any>;
  showBanner: boolean;
}

export const DualLayout = ({
  leftSlot,
  showBanner,
  children,
}: PropsWithChildren<props>) => {
  return (
    <div className="h-screen w-screen overflow-y:auto flex flex-col">
      {showBanner && (
        <div className="bg-palommaPurple rounded-md text-white text-center py-3 font-bold">
          <div className="flex m-2 items-center justify-center">
            <div>
              Paga con Palomma y gana créditos que puedes usar en tus tiendas
              favoritas.
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col lg:flex-row flex-grow">
        <div className="content w-full flex justify-center items-center">
          {leftSlot ? leftSlot : null}
        </div>

        <div className="w-full flex flex-grow justify-center items-center">
          <div className="w-full md:w-[27rem] max-w-[22rem] md:max-w-[27rem] px-2">
            {children ? children : <Outlet />}
          </div>
        </div>
      </div>
    </div>
  );
};
