import { useEffect } from "react";
import useSWR from "swr";
import { Auth, UserInformation } from "../layouts/PaymentRequestDetailsLayout";

export type Subscription = {
  id: string;
  amount_cents: number;
  merchant: string;
  status: "PENDING" | "CANCELLED" | "ACTIVE" | "PROCESSING";
  redirect_url?: string;
  interval: number;
  interval_unit: "week" | "month" | "year";
  count?: number;
  user_information?: UserInformation;
  enforced_document_number?: string;
};

const useSubscription = (auth: Auth, subscriptionId: string | null) => {
  const { data, error, isLoading, mutate } = useSWR<Subscription>(
    () =>
      subscriptionId && [
        `${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}`,
        auth,
      ]
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return {
    subscription: data,
    subscriptionLoading: isLoading,
    mutateSubscription: mutate,
  };
};

export default useSubscription;
