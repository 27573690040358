import { BlockTitles } from "../../../elements/BlockTitles";
import { CustomTextField } from "../../../elements/CustomTextField";
import { useState } from "react";
import { CustomButton } from "../../../elements/CustomButton";

interface VerifyOtpDaviplataApiProps {
  onContinue: (otp: string) => void;
  loading: boolean;
}

export const VerifyOtpDaviplataApi = ({
  onContinue,
  loading,
}: VerifyOtpDaviplataApiProps) => {
  const [otp, setOtp] = useState("");

  return (
    <div className="my-6 space-y-6">
      <BlockTitles
        title="Verifica tu cuenta Daviplata"
        subtitle="Confirma el mensaje de verificación que recibiste en tu celular para finalizar el proceso de vinculación"
      />

      <CustomTextField
        label="Código de verificación"
        fullWidth={true}
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />

      <CustomButton
        label="Confirmar"
        onClick={() => onContinue(otp)}
        fullWidth={true}
        disabled={!/^\d{6}$/.test(otp)}
        loading={loading}
      />
    </div>
  );
};
