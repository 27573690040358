import { useState } from "react";
import { BlockTitles } from "../../../elements/BlockTitles";
import { CustomButton } from "../../../elements/CustomButton";
import { CustomInputSelect } from "../../../elements/CustomInputSelect";
import { CustomTextField } from "../../../elements/CustomTextField";

type ConfirmAchProps = {
  onContinue: (account_type: string, account_number: string) => void;
  loading?: boolean;
};

export const ConfirmAch = ({ onContinue, loading }: ConfirmAchProps) => {
  const [account_type, setAccountType] = useState("SAVINGS");
  const [account_number, setAccountNumber] = useState("");
  const [account_number2, setAccountNumber2] = useState("");

  return (
    <div className="my-6">
      <BlockTitles
        title="Confirmar cuenta a debitar"
        subtitle="Ingresa tu información. Tus datos están encriptados y no se comparten con ningún comercio."
      />
      <div className="my-4">
        <CustomInputSelect
          label="Tipo de cuenta"
          fullWidth={true}
          documentTypes={[
            { value: "SAVINGS", label: "Ahorros" },
            { value: "CHECKING", label: "Corriente" },
          ]}
          value={account_type}
          onChange={(e) => {
            setAccountType(e.target.value);
          }}
        />
      </div>

      <div className="my-4">
        <CustomTextField
          label="Número de cuenta"
          fullWidth={true}
          value={account_number}
          onChange={(e) => {
            /^\d{0,20}$/.test(e.target.value) &&
              setAccountNumber(e.target.value);
          }}
        />
      </div>

      <div className="my-4">
        <CustomTextField
          label="Confirma tu número de cuenta"
          fullWidth={true}
          value={account_number2}
          onChange={(e) => {
            /^\d{0,20}$/.test(e.target.value) &&
              setAccountNumber2(e.target.value);
          }}
        />
      </div>

      <div className="mt-8">
        <CustomButton
          label="Continuar"
          disabled={account_number !== account_number2}
          fullWidth={true}
          onClick={() => onContinue(account_type, account_number)}
          loading={loading}
        />
      </div>
    </div>
  );
};
