import { ReactElement } from "react";

interface props {
  title: String;
  subtitle: String | ReactElement<any>;
}

export const BlockTitles = ({ title, subtitle }: props) => {
  return (
    <>
      <div className="my-4 w-full">
        <div className=" text-[25px] lg:text-[40px] leading-8 lg:leading-[3rem] font-[400] text-[#000000]">
          {title}
        </div>
      </div>

      <div className="text-[20px] font-[400] text-[#666666]">{subtitle}</div>
    </>
  );
};
