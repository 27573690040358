import { Merchant } from "../../hooks/useMerchant";
import { Subscription } from "../../hooks/useSubscription";
import logo from "../../assets/logo.png";

interface PaymentRequestDetailsProps {
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  merchant: Merchant;
  merchant_display_name?: string;
  subtotal: number;
  discount: number;
  subscription?: Subscription;
}

const formatAmount = (amount_cents: number) => {
  return `$${(amount_cents / 100)
    .toLocaleString("es-ES", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    })
    .replace(/,/g, ".")}`;
};

const formatInterval = (interval: number, interval_unit: string) => {
  const intervalUnitDisplay: { [key: string]: string } =
    interval === 1
      ? {
          hour: "hora",
          day: "día",
          week: "semana",
          month: "mes",
          year: "año",
        }
      : {
          hour: "horas",
          day: "días",
          week: "semanas",
          month: "meses",
          year: "años",
        };

  return `${interval} ${intervalUnitDisplay[interval_unit]}`;
};

export const PaymentRequestDetails = (props: PaymentRequestDetailsProps) => {
  const total = props.subtotal - props.discount;

  return (
    <>
      <div className="w-full flex justify-center flex-col items-center">
        <div className="px-8 py-4 mx-4 mt-6 mb-2 rounded-2xl w-2/3 min-w-fit max-w-[380px] sm:w-[380px] bg-white">
          {(props.merchant.logo_url ||
            props.merchant_display_name === "Hapi") && (
            <div className="mb-2">
              <img
                className="object-contain max-h-12 lg:max-h-14"
                src={
                  props.merchant.logo_url ||
                  "https://palomma-logos-merchants.s3.amazonaws.com/hapi.png"
                }
                alt="logo"
              />
            </div>
          )}

          <div className="flex flex-col">
            <div className="mx-4">
              {props.subscription && (
                <div className="mb-4">
                  <div className="underline text-lg">
                    Detalles de suscripción:
                  </div>
                  <div className="">
                    <div className="my-2 flex">
                      <div className="text-[16px] font-[400] text-black mr-4">
                        Total:
                      </div>
                      <div className="text-[16px] font-semibold text-black">
                        {formatAmount(props.subtotal)}
                      </div>
                    </div>

                    <div className="my-2 flex">
                      <div className="text-[16px] font-[400] text-black mr-4">
                        Frecuencia:
                      </div>
                      <div className="text-[16px] font-semibold text-black">
                        {formatInterval(
                          props.subscription.interval,
                          props.subscription.interval_unit
                        )}
                      </div>
                    </div>
                    {props.subscription.count && (
                      <div className="my-2 flex">
                        <div className="text-[16px] font-[400] text-black mr-4">
                          Cuotas:
                        </div>
                        <div className="text-[16px] font-semibold text-black">
                          {props.subscription.count}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div>
                <div className="underline text-lg">Por pagar hoy:</div>

                <div>
                  {props.discount > 0 && (
                    <>
                      <div className="my-2 flex">
                        <div className="text-[16px] font-[400] text-black mr-4">
                          Total:
                        </div>
                        <div className="text-[16px] font-semibold text-black">
                          {formatAmount(props.subtotal)}
                        </div>
                      </div>
                      <div className="my-2 flex">
                        <div className="text-[16px] font-semibold text-palommaPurple mr-4">
                          (-) Descuento:
                        </div>
                        <div className="text-[16px] font-semibold text-black">
                          {formatAmount(props.discount)}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="mt-2 flex">
                    <div className="text-[16px] font-[400] text-black mr-4">
                      Total a Debitar:
                    </div>
                    <div className="text-[16px] font-semibold text-black">
                      {formatAmount(total)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 mx-4 mb-4 rounded-2xl w-2/3 sm:w-[380px]">
          <div className="my-4 text-center font-[400] text-[#666666] hidden sm:flex">
            Sistema de pago a{" "}
            {props.merchant_display_name || props.merchant.name} operado por
            Palomma, empresa Colombiana de pagos en línea.
          </div>
          <div>
            <div className="flex items-center justify-center text-palommaPurple">
              Powered by{" "}
              <img
                className="object-contain max-h-8 lg:max-h-10"
                src={logo}
                alt="logo"
              />
            </div>
          </div>

          {props.authToken && (
            <div
              className="flex items-center justify-center text-palommaPurple opacity-70 mt-2 font-[300] hover:underline hover:cursor-pointer"
              onClick={() => props.setAuthToken(null)}
            >
              Cerrar Sesión
            </div>
          )}
        </div>
      </div>
    </>
  );
};
