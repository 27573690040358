import { useState } from "react";
import { PaymentAccountWithRail } from "../../../hooks/usePaymentAccount";
import { BlockTitles } from "../../elements/BlockTitles";
import { CustomButton } from "../../elements/CustomButton";
import { CustomCheckbox } from "../../elements/CustomCheckbox";
import { Divider } from "../../elements/Divider";
import { OptionsBox } from "../../elements/OptionsBox";
import { BankWithPaymentRail } from "../../../hooks/useBank";
import { PaymentDetails } from "../../../layouts/PaymentRequestDetailsLayout";

interface ConfirmPaymentAccountProps {
  paymentDetails: PaymentDetails;
  paymentAccount: PaymentAccountWithRail;
  bank: BankWithPaymentRail;
  merchantName: string;
  terms?: string;
  processTransaction: () => void;
  processTransactionLoading: boolean;
  subscription?: string | undefined;
  processSubscription?: () => void;
  processSubscriptionLoading?: boolean;
  changeAccount: () => void;
  identificationsMatch: boolean;
}

export const ConfirmPaymentAccount = (props: ConfirmPaymentAccountProps) => {
  const [terms, setTerms] = useState(false);
  const disabled_rails = new Set(props.bank.rails_disabled_for_payments);

  return (
    <>
      <BlockTitles
        title="Confirmemos desde donde vas a pagar"
        subtitle={
          props.subscription
            ? "Los pagos se debitarán desde esta cuenta"
            : "El pago se debitará desde esta cuenta"
        }
      />

      <div className="py-4">
        <Divider />
      </div>

      <div className="flex items-center py-4 mb-5">
        <div className="w-[90%]">
          <div className="font-[400] text-[16px] text-[#454545]">
            {props.paymentAccount.bank.toUpperCase().split("_")[0]}
          </div>
          <div className="text-[20px] font-[400]">
            {props.paymentAccount.description}
          </div>
        </div>
        <OptionsBox title="Eliminar" onClick={props.changeAccount} />
      </div>

      {props.terms && (
        <CustomCheckbox
          label={
            <>
              {"Acepto los "}{" "}
              <a href={props.terms} target="_blank" rel="noopener noreferrer">
                <u>términos y condiciones y política de privacidad</u>
              </a>
            </>
          }
          value={terms}
          onChange={() => setTerms(!terms)}
        />
      )}

      <div className=" my-3 py-3 w-full">
        <CustomButton
          label={props.subscription ? "Aprobar suscripción" : "Pagar"}
          disabled={
            !props.identificationsMatch ||
            (!!props.terms && !terms) ||
            disabled_rails.has(props.paymentAccount.payment_rail) ||
            (props.paymentAccount.payment_rail === "daviplata_api" &&
              props.paymentDetails.amount_cents > 200000000)
          }
          fullWidth={true}
          onClick={
            props.subscription
              ? props.processSubscription
              : props.processTransaction
          }
          loading={
            props.subscription
              ? props.processSubscriptionLoading
              : props.processTransactionLoading
          }
        />

        {disabled_rails.has(props.paymentAccount.payment_rail) && (
          <div className="text-[14px] pt-4  font-[400] text-[#454545]">
            {`${props.bank.name} está presentando fallos. Elimina y vuelve a vincular tu cuenta para pagar.`}
          </div>
        )}

        {props.paymentAccount.payment_rail === "daviplata_api" &&
          props.paymentDetails.amount_cents > 200000000 && (
            <div className="text-[14px] pt-4  font-[400] text-[#454545]">
              {`Daviplata no permite hacer pagos por encima de $2.000.000. Por favor cambia la cuenta a una que permita hacer pagos por este valor para completar el pago.`}
            </div>
          )}

        {!props.identificationsMatch && (
          <div className="text-[14px] pt-4  font-[400] text-[#454545]">
            La cédula asociada a la cuenta{" "}
            {props.paymentAccount.bank.charAt(0).toUpperCase() +
              props.paymentAccount.bank.slice(1).toLowerCase()}{" "}
            no coincide con la cédula registrada en {props.merchantName}. Por
            favor cambie la cuenta a una con la cédula correcta para completar
            el pago.
          </div>
        )}
      </div>
    </>
  );
};
