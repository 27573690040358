import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { useSnackbar } from "material-ui-snackbar-provider";
import { BankSelection } from "../components/templates/link-account/BankSelection";
import { Loading } from "../components/elements/Loading";
import { StatusMessage } from "../components/elements/StatusMessage";
import { PaymentRequestOutletContext } from "../layouts/PaymentRequestDetailsLayout";
import usePaymentAccount, {
  PaymentAccountWithRail,
} from "../hooks/usePaymentAccount";
import { CustomSteppers } from "../components/elements/CustomSteppers";
import useBank from "../hooks/useBank";
import { ConfirmBancolombiaToken } from "../components/templates/link-account/bancolombia_token/ConfirmBancolombiaToken";
import { ConfirmNequiToken } from "../components/templates/link-account/nequi_token/ConfirmNequiToken";
import jwtDecode from "jwt-decode";
import usePaymentAccounts from "../hooks/usePaymentAccounts";
import { ConfirmPse } from "../components/templates/link-account/pse/ConfirmPse";
import useBanks from "../hooks/useBanks";
import { ConfirmAch } from "../components/templates/link-account/ach/ConfirmAch";
import { VerifyOtpDaviplataApi } from "../components/templates/link-account/daviplata_api/VerifyOtpDaviplataApi";
import { ConfirmDaviplataApi } from "../components/templates/link-account/daviplata_api/ConfirmDaviplataApi";
import useMerchant from "../hooks/useMerchant";

////////////////////////////////////////////////////
/////////// Helper functions ///////////////////////
////////////////////////////////////////////////////

const createPaymentAccountRequest = async (authToken: string, body: object) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/payment-accounts`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include",
    }
  );
  if (response.ok) {
    const paymentAccount: PaymentAccountWithRail = await response.json();
    return paymentAccount;
  } else {
    throw response;
  }
};

const verifyPaymentAccountRequest = async (
  authToken: string,
  paymentAccountId: string,
  otp: string
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/payment-accounts/${paymentAccountId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        procedure: "VERIFY_DAVIPLATA_API",
        otp,
      }),
      credentials: "include",
    }
  );
  if (response.ok) {
    const paymentAccount: PaymentAccountWithRail = await response.json();
    return paymentAccount;
  } else {
    throw response;
  }
};

////////////////////////////////////////////////////
////////////////// Component ///////////////////////
////////////////////////////////////////////////////

export default function LinkAccount() {
  const location = useLocation();
  const snackbar = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [paymentAccountActionLoading, setCreatePaymentAccountLoading] =
    useState(false);

  const { auth, paymentDetails } =
    useOutletContext<PaymentRequestOutletContext>();

  const { merchant, merchantLoading } = useMerchant(paymentDetails.merchant);

  const paymentAccountId = searchParams.get("cuenta");
  const { paymentAccount, paymentAccountLoading, mutatePaymentAccount } =
    usePaymentAccount(auth, paymentAccountId);
  const { mutatePaymentAccounts } = usePaymentAccounts(auth);

  const { banks: b, banksLoading } = useBanks();
  const [bankId, setBankId] = useState<string | null>(null);
  const { bank, bankLoading } = useBank(bankId);

  const banks =
    merchant?.id === "insights" && b
      ? b.filter((x) => {
          return !(x.id.includes("nequi") || x.id.includes("daviplata"));
        })
      : b;

  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    if (shouldRedirect && paymentAccount?.auth_url)
      window.location.replace(paymentAccount.auth_url);
  }, [shouldRedirect, paymentAccount]);

  if (paymentAccountId && !paymentAccountLoading && !paymentAccount) {
    return (
      <StatusMessage
        title="No encontramos tu cuenta"
        subtitle="No pudimos encontrar la cuenta que intentas vincular. Inténtalo más tarde"
        success={false}
      />
    );
  }

  if (!auth.user) return <Navigate to={`/login${location.search}`} replace />;
  if (auth.user.status === "PENDING")
    return <Navigate to={`/register${location.search}`} replace />;

  if (banksLoading || bankLoading || paymentAccountLoading || merchantLoading)
    return <Loading message="Cargando..." />;

  if (!banks)
    return (
      <StatusMessage
        title="Mmm... algo ocurrió"
        subtitle="No pudimos cargar los bancos disponibles. Inténtalo más tarde"
        success={false}
      />
    );

  if (paymentAccount?.status === "PENDING") {
    switch (paymentAccount.payment_rail) {
      case "bancolombia_token":
        return (
          <Loading
            message="Esperando confirmación de vinculación con Bancolombia"
            footerText="Esto puede tardar varios segundos"
          />
        );
      case "nequi_token":
        return <Loading message="Confirma el código en tu aplicación Nequi" />;
      case "daviplata_api":
        return (
          <VerifyOtpDaviplataApi
            onContinue={async (otp: string) => {
              setCreatePaymentAccountLoading(true);
              try {
                const processedPaymentAccount =
                  await verifyPaymentAccountRequest(
                    auth.token!,
                    paymentAccount.id,
                    otp
                  );
                mutatePaymentAccount(processedPaymentAccount);
              } catch (err: any) {
                console.error(err);
                if ("status" in err && err.status === 401) auth.setToken(null);
                else if ("status" in err && err.status === 400) {
                  snackbar.showMessage(
                    "El código que ingresaste es incorrecto. Vuelve a intentarlo."
                  );
                  searchParams.delete("cuenta");
                  navigate(`/?${searchParams.toString()}`);
                } else
                  snackbar.showMessage("Hubo un error. Inténtalo de nuevo.");
              }
              setCreatePaymentAccountLoading(false);
            }}
            loading={paymentAccountActionLoading}
          />
        );
    }
  }

  if (paymentAccount?.status === "AVAILABLE") {
    mutatePaymentAccounts(undefined);
    searchParams.delete("cuenta");
    navigate(`/?${searchParams.toString()}`);
  }

  if (paymentAccount?.status === "ERROR")
    return (
      <StatusMessage
        title="Mmm... algo ocurrió"
        subtitle="No pudimos vincular tu cuenta con Palomma. No te preocupes, tus datos están seguros... vuelve a intentarlo"
        success={false}
        button={{
          text: "Reintentar",
          onClick: () => {
            setBankId(null);
            searchParams.delete("cuenta");
            setSearchParams(searchParams);
          },
        }}
      />
    );

  if (!bank)
    return (
      <>
        <CustomSteppers
          steps={["Datos", "Vincular", "Confirmar"]}
          activeStep={1}
        />
        <BankSelection
          banks={banks}
          onBankSelection={setBankId}
          bankSelectionLoading={bankLoading}
          pseDisabled={!!paymentDetails.subscription || merchant?.pse_disabled}
          achDisabled={!paymentDetails.subscription || merchant?.ach_disabled}
          bancolombiaTokenDisabled={paymentDetails.amount_cents > 100000000}
          isBusinessEntity={auth.user.user_type === "BUSINESS" ? true : false}
        />
      </>
    );

  if (bank.linking_disabled)
    return (
      <StatusMessage
        success={false}
        title={`${bank.name} está presentando fallas.`}
        subtitle="¡Lo sentimos! Estamos trabajando con tu banco para solucionarlas. Intenta tu pago nuevamente más tarde."
      />
    );

  const createPaymentAccount = async (railSpecificBody: {
    payment_rail:
      | "bancolombia_token"
      | "nequi_token"
      | "daviplata_api"
      | "pse"
      | "ach";
    [k: string]: any;
  }) => {
    setCreatePaymentAccountLoading(true);
    try {
      const paymentAccount = await createPaymentAccountRequest(
        auth.token!,
        railSpecificBody
      );

      if (railSpecificBody.payment_rail === "pse") {
        mutatePaymentAccounts(undefined);
        navigate(`/${location.search}`);
      } else {
        searchParams.set("cuenta", paymentAccount.id);
        setSearchParams(searchParams);
      }
    } catch (err: any) {
      console.error(await err.json());

      if ("status" in err && err.status === 401) auth.setToken(null);
      else if ("status" in err && err.status === 404)
        snackbar.showMessage(
          "No encontramos tu cuenta. Valídala y vuelve a intentar."
        );
      else snackbar.showMessage("Hubo un error. Inténtalo de nuevo.");
    }
    setCreatePaymentAccountLoading(false);
  };

  switch (bank.payment_rail) {
    case "bancolombia_token": {
      const onContinue = async () => {
        await createPaymentAccount({
          payment_rail: "bancolombia_token",
          acceptance_token: bank.acceptance_token,
          redirect_url_query_params: location.search,
        });
        setShouldRedirect(true);
      };
      return (
        <>
          <CustomSteppers
            steps={["Datos", "Vincular", "Confirmar"]}
            activeStep={2}
          />
          <ConfirmBancolombiaToken
            onContinue={onContinue}
            loading={paymentAccountActionLoading}
            terms_link={jwtDecode<any>(bank.acceptance_token).permalink}
          />
        </>
      );
    }
    case "nequi_token": {
      const onContinue = async (phone_number: string) => {
        await createPaymentAccount({
          payment_rail: "nequi_token",
          acceptance_token: bank.acceptance_token,
          phone_number,
        });
      };
      return (
        <>
          <CustomSteppers
            steps={["Datos", "Vincular", "Confirmar"]}
            activeStep={2}
          />
          <ConfirmNequiToken
            onContinue={onContinue}
            loading={paymentAccountActionLoading}
            terms_link={jwtDecode<any>(bank.acceptance_token).permalink}
            default_phone={auth.user.phone}
          />
        </>
      );
    }
    case "daviplata_api": {
      const onContinue = async (
        documentType: string,
        documentNumber: string,
        phoneNumber: string
      ) => {
        await createPaymentAccount({
          payment_rail: "daviplata_api",
          document_type: documentType,
          document_number: documentNumber,
          phone_number: phoneNumber,
        });
      };
      return (
        <>
          <CustomSteppers
            steps={["Datos", "Vincular", "Confirmar"]}
            activeStep={2}
          />
          <ConfirmDaviplataApi
            onContinue={onContinue}
            loading={paymentAccountActionLoading}
            default_document_type={auth.user.document_type}
            default_document_number={auth.user.document_number}
            default_phone_number={auth.user.phone}
          />
        </>
      );
    }
    case "pse": {
      const onContinue = async () => {
        await createPaymentAccount({
          payment_rail: "pse",
          bank: bank.id,
        });
      };
      return (
        <>
          <CustomSteppers
            steps={["Datos", "Vincular", "Confirmar"]}
            activeStep={2}
          />
          <ConfirmPse
            bankName={bank.name}
            onContinue={onContinue}
            loading={paymentAccountActionLoading}
          />
        </>
      );
    }
    case "ach": {
      const onContinue = async (
        account_type: string,
        account_number: string
      ) => {
        await createPaymentAccount({
          payment_rail: "ach",
          bank: bank.id,
          account_type,
          account_number,
        });
      };
      return (
        <>
          <CustomSteppers
            steps={["Datos", "Vincular", "Confirmar"]}
            activeStep={2}
          />
          <ConfirmAch
            onContinue={onContinue}
            loading={paymentAccountActionLoading}
          />
        </>
      );
    }
  }
}
