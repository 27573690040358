interface props {
  title: String;
  subtitle?: String;
  // labelValue?: any;
  checked?: boolean;
  onClick?: () => void;
}

export const RadioOptionsBox = ({
  title,
  subtitle,
  // labelValue,
  checked,
  onClick,
}: props) => {
  return (
    <label
      // htmlFor={labelValue}
      className={`flex bg-white cursor-pointer hover:bg-gray-200 items-center py-2 rounded-xl gap-4 mt-4 border`}
    >
      <input
        // id={labelValue}
        type="radio"
        name="account"
        checked={checked}
        onClick={onClick}
        className="cursor-pointer mx-4 w-5 h-5 accent-palommaPurple"
      />

      <div className="cursor-pointer">
        <div className="font-[400] text-[16px] text-[#454545]">{title}</div>
        {subtitle && <div className="text-[20px] font-[400]">{subtitle}</div>}
      </div>
    </label>
  );
};
