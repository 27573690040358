import React from "react";
import { CustomTextField } from "../../elements/CustomTextField";
import { CustomButtonOutline } from "../../elements/CustomButtonOutline";
import { CustomButton } from "../../elements/CustomButton";
import { BlockTitles } from "../../elements/BlockTitles";
import stytchLogo from "../../../assets/stytch.png";

interface VerifyCodeProps {
  phone_number: string;
  // pinCode: string;
  // setPinCode: (pinCode: string) => void;
  mfaCode: string;
  setMfaCode: (mfaCode: string) => void;
  onContinue: () => void;
  resendCode: () => void;
  resendEnabled: boolean;
  loading: boolean;
}

export const VerifyCode = (props: VerifyCodeProps) => {
  return (
    <div>
      <BlockTitles
        title="Confirma tu número"
        subtitle={
          <React.Fragment>
            Ingresa el código que te enviamos por teléfono al: <br />
            <div className="mt-[8px]" />
            <span className="text-[24px] text-[#000000] font-[600]">
              {props.phone_number}
            </span>
          </React.Fragment>
        }
      />

      <div className="border-line">
        <div className="border-t-4 py-8 my-4 w-full">
          <div className="flex flex-col">
            <CustomTextField
              label="Código Teléfono"
              autoComplete="one-time-code"
              inputProps={{ inputMode: "numeric" }}
              fullWidth={true}
              value={props.mfaCode}
              onChange={(e) =>
                /^\d{0,6}$/.test(e.target.value) &&
                props.setMfaCode(e.target.value)
              }
            />
          </div>

          <div className="grid grid-cols-2 gap-2 mt-[24px]">
            <CustomButtonOutline
              label="Reenviar"
              disabled={!props.resendEnabled || props.loading}
              fullWidth={true}
              onClick={props.resendCode}
            />
            <CustomButton
              label="Continuar"
              disabled={!/^\d{6}$/.test(props.mfaCode)}
              fullWidth={true}
              onClick={props.onContinue}
              loading={props.loading}
            />
          </div>
          <div className="text-[14px] lg:text-[16px] font-[400] text-[#666666]  mt-4">
            Busca el código que manda nuestro proveedor de verificación:
            <div className="inline-flex mx-2  align-middle">
              <img className="h-5" src={stytchLogo} alt="" />
            </div>
          </div>
        </div>

        {/* <div className="flex">
          <FormControlLabel
            sx={{
              color: "#959595",
              fontSize: 18,
            }}
            control={
              <Checkbox
                sx={{
                  color: "#959595",
                  "&.Mui-checked": {
                    color: "#5D26C5",
                  },
                }}
              />
            }
            label="Cerrar mi sesión despues de hacer mi pago"
          />
        </div> */}
      </div>
    </div>
  );
};
